<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
    
        
          <ng-container>
            <div class="content-header row">
              <div class="content-header-left col-md-6 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12 d-flex">
                    <h2 class="content-header-title float-left mb-0">
                     Visiteurs siteweb
                    </h2>
  
  
                  </div>
                </div>
              </div>
              <ng-container>
                <div class="content-header-right text-md-right col-md-6 col-12 d-md-block2 d-none2">
                  <div class="form-group breadcrum-right">
                               
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>


          <div class="row mt-75">
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ total_visiteurs }}</h2>
                    <p class="card-text">Total visiteurs</p>
                  </div>
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="cpu" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_facebook }}</h2>
                    <p class="card-text">Visiteurs de facebook</p>
                  </div>
                  <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="server" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_google }}</h2>
                    <p class="card-text">Visiteurs de google</p>
                  </div>
                  <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="activity" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_others }}</h2>
                    <p class="card-text">Autres visiteurs</p>
                  </div>
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="alert-octagon" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

  
          <section class="produits-list-wrapper mt-75">
            <div class="card">
              
              <ngx-datatable
                [rows]="rows"
                [rowHeight]="58"
                [sorts]="[{prop: 'createdAt', dir: 'desc'}]"
                class="bootstrap core-bootstrap"
                [limit]="10"
                [columnMode]="ColumnMode.force"
                [headerHeight]="40"
                [footerHeight]="50"
                [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox"
                [limit]="selectedOption"
              >
                <ngx-datatable-column
                  [width]="50"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                        id="headerChkbxRef"
                      />
                      <label class="custom-control-label" for="headerChkbxRef"></label>
                    </div>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)"
                        id="rowChkbxRef{{ rowIndex }}"
                      />
                      <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                
                <ngx-datatable-column name="Id" prop="id" ></ngx-datatable-column>


                <ngx-datatable-column name="Ajouté le" prop="createdAt">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>                    

                    <div class="d-flex align-items-center">
                      <div class="bbbbbb" [ngClass]="{'bg-success' : estAujourdhui(row.createdAt), 'bg-secondary222' :!estAujourdhui(row.createdAt) }"></div>{{ row.createdAt | date:'yyyy-MM-dd HH:mm' }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column name="Pays" prop="pays" >
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <i class=" mr-50 flag-icon flag-icon-{{row.pays | lowercase  }}"></i> {{ getPaysName(row.pays)  }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="google" prop="google" >
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="badge badge-light-success">{{ row.google }}</div>
                  </ng-template>
              </ngx-datatable-column>

                <ngx-datatable-column name="facebook" prop="facebook" >
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                        <div class="badge badge-light-info">{{ row.facebook }}</div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="others" prop="others" >
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                        <div class="badge badge-light-danger">{{ row.others }}</div>
                    </ng-template>
                </ngx-datatable-column>
  

                <ngx-datatable-column name="Url" prop="url" [width]="150">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    
                    <ng-template #tipContentVenteee>
                      <div class="mb-50">{{ row.url  }} </div>
                    </ng-template>

                    <div placement="top" [ngbTooltip]="tipContentVenteee" container="body">
                      <span>{{ row.url.substring(0, 30) }}</span>
                    </div>
                    
                  </ng-template>
                </ngx-datatable-column>
                
                
  
              </ngx-datatable>
              
            </div>
          </section>
  
  
  
    </div>
  </div>
  
  

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  pays = [{
    "id": 0,
    "code": "AF",
    "country": "Afghanistan"
}, {
    "id": 1,
    "code": "ZA",
    "country": "Afrique du Sud"
}, {
    "id": 2,
    "code": "AL",
    "country": "Albanie"
}, {
    "id": 3,
    "code": "DZ",
    "country": "Alg\u00e9rie"
}, {
    "id": 4,
    "code": "DE",
    "country": "Allemagne"
}, {
    "id": 5,
    "code": "AD",
    "country": "Andorre"
}, {
    "id": 6,
    "code": "AO",
    "country": "Angola"
}, {
    "id": 7,
    "code": "AI",
    "country": "Anguilla"
}, {
    "id": 8,
    "code": "AQ",
    "country": "Antarctique"
}, {
    "id": 9,
    "code": "AG",
    "country": "Antigua-et-Barbuda"
}, {
    "id": 10,
    "code": "SA",
    "country": "Arabie saoudite"
}, {
    "id": 11,
    "code": "AR",
    "country": "Argentine"
}, {
    "id": 12,
    "code": "AM",
    "country": "Arm\u00e9nie"
}, {
    "id": 13,
    "code": "AW",
    "country": "Aruba"
}, {
    "id": 14,
    "code": "AU",
    "country": "Australie"
}, {
    "id": 15,
    "code": "AT",
    "country": "Autriche"
}, {
    "id": 16,
    "code": "AZ",
    "country": "Azerba\u00efdjan"
}, {
    "id": 17,
    "code": "BS",
    "country": "Bahamas"
}, {
    "id": 18,
    "code": "BH",
    "country": "Bahre\u00efn"
}, {
    "id": 19,
    "code": "BD",
    "country": "Bangladesh"
}, {
    "id": 20,
    "code": "BB",
    "country": "Barbade"
}, {
    "id": 21,
    "code": "BE",
    "country": "Belgique"
}, {
    "id": 22,
    "code": "BZ",
    "country": "Belize"
}, {
    "id": 23,
    "code": "BJ",
    "country": "B\u00e9nin"
}, {
    "id": 24,
    "code": "BM",
    "country": "Bermudes"
}, {
    "id": 25,
    "code": "BT",
    "country": "Bhoutan"
}, {
    "id": 26,
    "code": "BY",
    "country": "Bi\u00e9lorussie"
}, {
    "id": 27,
    "code": "BO",
    "country": "Bolivie"
}, {
    "id": 28,
    "code": "BA",
    "country": "Bosnie-Herz\u00e9govine"
}, {
    "id": 29,
    "code": "BW",
    "country": "Botswana"
}, {
    "id": 30,
    "code": "BR",
    "country": "Br\u00e9sil"
}, {
    "id": 31,
    "code": "BN",
    "country": "Brunei"
}, {
    "id": 32,
    "code": "BG",
    "country": "Bulgarie"
}, {
    "id": 33,
    "code": "BF",
    "country": "Burkina Faso"
}, {
    "id": 34,
    "code": "BI",
    "country": "Burundi"
}, {
    "id": 35,
    "code": "KH",
    "country": "Cambodge"
}, {
    "id": 36,
    "code": "CM",
    "country": "Cameroun"
}, {
    "id": 37,
    "code": "CA",
    "country": "Canada"
}, {
    "id": 38,
    "code": "CV",
    "country": "Cap-Vert"
}, {
    "id": 39,
    "code": "CL",
    "country": "Chili"
}, {
    "id": 40,
    "code": "CN",
    "country": "Chine"
}, {
    "id": 41,
    "code": "CY",
    "country": "Chypre"
}, {
    "id": 42,
    "code": "CO",
    "country": "Colombie"
}, {
    "id": 43,
    "code": "KM",
    "country": "Comores"
}, {
    "id": 44,
    "code": "CG",
    "country": "Congo-Brazzaville"
}, {
    "id": 45,
    "code": "CD",
    "country": "Congo-Kinshasa"
}, {
    "id": 46,
    "code": "KP",
    "country": "Cor\u00e9e du Nord"
}, {
    "id": 47,
    "code": "KR",
    "country": "Cor\u00e9e du Sud"
}, {
    "id": 48,
    "code": "CR",
    "country": "Costa Rica"
}, {
    "id": 49,
    "code": "CI",
    "country": "C\u00f4te d\u2019Ivoire"
}, {
    "id": 50,
    "code": "HR",
    "country": "Croatie"
}, {
    "id": 51,
    "code": "CU",
    "country": "Cuba"
}, {
    "id": 52,
    "code": "CW",
    "country": "Cura\u00e7ao"
}, {
    "id": 53,
    "code": "DK",
    "country": "Danemark"
}, {
    "id": 54,
    "code": "DJ",
    "country": "Djibouti"
}, {
    "id": 55,
    "code": "DM",
    "country": "Dominique"
}, {
    "id": 56,
    "code": "EG",
    "country": "\u00c9gypte"
}, {
    "id": 57,
    "code": "AE",
    "country": "\u00c9mirats arabes unis"
}, {
    "id": 58,
    "code": "EC",
    "country": "\u00c9quateur"
}, {
    "id": 59,
    "code": "ER",
    "country": "\u00c9rythr\u00e9e"
}, {
    "id": 60,
    "code": "ES",
    "country": "Espagne"
}, {
    "id": 61,
    "code": "EE",
    "country": "Estonie"
}, {
    "id": 62,
    "code": "SZ",
    "country": "Eswatini"
}, {
    "id": 63,
    "code": "VA",
    "country": "\u00c9tat de la Cit\u00e9 du Vatican"
}, {
    "id": 64,
    "code": "US",
    "country": "\u00c9tats-Unis"
}, {
    "id": 65,
    "code": "ET",
    "country": "\u00c9thiopie"
}, {
    "id": 66,
    "code": "FJ",
    "country": "Fidji"
}, {
    "id": 67,
    "code": "FI",
    "country": "Finlande"
}, {
    "id": 68,
    "code": "FR",
    "country": "France"
}, {
    "id": 69,
    "code": "GA",
    "country": "Gabon"
}, {
    "id": 70,
    "code": "GM",
    "country": "Gambie"
}, {
    "id": 71,
    "code": "GE",
    "country": "G\u00e9orgie"
}, {
    "id": 72,
    "code": "GS",
    "country": "G\u00e9orgie du Sud-et-les \u00celes Sandwich du Sud"
}, {
    "id": 73,
    "code": "GH",
    "country": "Ghana"
}, {
    "id": 74,
    "code": "GI",
    "country": "Gibraltar"
}, {
    "id": 75,
    "code": "GR",
    "country": "Gr\u00e8ce"
}, {
    "id": 76,
    "code": "GD",
    "country": "Grenade"
}, {
    "id": 77,
    "code": "GL",
    "country": "Groenland"
}, {
    "id": 78,
    "code": "GP",
    "country": "Guadeloupe"
}, {
    "id": 79,
    "code": "GU",
    "country": "Guam"
}, {
    "id": 80,
    "code": "GT",
    "country": "Guatemala"
}, {
    "id": 81,
    "code": "GG",
    "country": "Guernesey"
}, {
    "id": 82,
    "code": "GN",
    "country": "Guin\u00e9e"
}, {
    "id": 83,
    "code": "GQ",
    "country": "Guin\u00e9e \u00e9quatoriale"
}, {
    "id": 84,
    "code": "GW",
    "country": "Guin\u00e9e-Bissau"
}, {
    "id": 85,
    "code": "GY",
    "country": "Guyana"
}, {
    "id": 86,
    "code": "GF",
    "country": "Guyane fran\u00e7aise"
}, {
    "id": 87,
    "code": "HT",
    "country": "Ha\u00efti"
}, {
    "id": 88,
    "code": "HN",
    "country": "Honduras"
}, {
    "id": 89,
    "code": "HU",
    "country": "Hongrie"
}, {
    "id": 90,
    "code": "BV",
    "country": "\u00cele Bouvet"
}, {
    "id": 91,
    "code": "CX",
    "country": "\u00cele Christmas"
}, {
    "id": 92,
    "code": "IM",
    "country": "\u00cele de Man"
}, {
    "id": 93,
    "code": "NF",
    "country": "\u00cele Norfolk"
}, {
    "id": 94,
    "code": "AX",
    "country": "\u00celes \u00c5land"
}, {
    "id": 95,
    "code": "KY",
    "country": "\u00celes Ca\u00efmans"
}, {
    "id": 96,
    "code": "CC",
    "country": "\u00celes Cocos"
}, {
    "id": 97,
    "code": "CK",
    "country": "\u00celes Cook"
}, {
    "id": 98,
    "code": "FO",
    "country": "\u00celes F\u00e9ro\u00e9"
}, {
    "id": 99,
    "code": "HM",
    "country": "\u00celes Heard-et-MacDonald"
}, {
    "id": 100,
    "code": "FK",
    "country": "\u00celes Malouines"
}, {
    "id": 101,
    "code": "MP",
    "country": "\u00celes Mariannes du Nord"
}, {
    "id": 102,
    "code": "MH",
    "country": "\u00celes Marshall"
}, {
    "id": 103,
    "code": "UM",
    "country": "\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis"
}, {
    "id": 104,
    "code": "PN",
    "country": "\u00celes Pitcairn"
}, {
    "id": 105,
    "code": "SB",
    "country": "\u00celes Salomon"
}, {
    "id": 106,
    "code": "TC",
    "country": "\u00celes Turques-et-Ca\u00efques"
}, {
    "id": 107,
    "code": "VG",
    "country": "\u00celes Vierges britanniques"
}, {
    "id": 108,
    "code": "VI",
    "country": "\u00celes Vierges des \u00c9tats-Unis"
}, {
    "id": 109,
    "code": "IN",
    "country": "Inde"
}, {
    "id": 110,
    "code": "ID",
    "country": "Indon\u00e9sie"
}, {
    "id": 111,
    "code": "IQ",
    "country": "Irak"
}, {
    "id": 112,
    "code": "IR",
    "country": "Iran"
}, {
    "id": 113,
    "code": "IE",
    "country": "Irlande"
}, {
    "id": 114,
    "code": "IS",
    "country": "Islande"
}, {
    "id": 115,
    "code": "IL",
    "country": "Isra\u00ebl"
}, {
    "id": 116,
    "code": "IT",
    "country": "Italie"
}, {
    "id": 117,
    "code": "JM",
    "country": "Jama\u00efque"
}, {
    "id": 118,
    "code": "JP",
    "country": "Japon"
}, {
    "id": 119,
    "code": "JE",
    "country": "Jersey"
}, {
    "id": 120,
    "code": "JO",
    "country": "Jordanie"
}, {
    "id": 121,
    "code": "KZ",
    "country": "Kazakhstan"
}, {
    "id": 122,
    "code": "KE",
    "country": "Kenya"
}, {
    "id": 123,
    "code": "KG",
    "country": "Kirghizstan"
}, {
    "id": 124,
    "code": "KI",
    "country": "Kiribati"
}, {
    "id": 125,
    "code": "KW",
    "country": "Kowe\u00eft"
}, {
    "id": 126,
    "code": "RE",
    "country": "La R\u00e9union"
}, {
    "id": 127,
    "code": "LA",
    "country": "Laos"
}, {
    "id": 128,
    "code": "LS",
    "country": "Lesotho"
}, {
    "id": 129,
    "code": "LV",
    "country": "Lettonie"
}, {
    "id": 130,
    "code": "LB",
    "country": "Liban"
}, {
    "id": 131,
    "code": "LR",
    "country": "Liberia"
}, {
    "id": 132,
    "code": "LY",
    "country": "Libye"
}, {
    "id": 133,
    "code": "LI",
    "country": "Liechtenstein"
}, {
    "id": 134,
    "code": "LT",
    "country": "Lituanie"
}, {
    "id": 135,
    "code": "LU",
    "country": "Luxembourg"
}, {
    "id": 136,
    "code": "MK",
    "country": "Mac\u00e9doine du Nord"
}, {
    "id": 137,
    "code": "MG",
    "country": "Madagascar"
}, {
    "id": 138,
    "code": "MY",
    "country": "Malaisie"
}, {
    "id": 139,
    "code": "MW",
    "country": "Malawi"
}, {
    "id": 140,
    "code": "MV",
    "country": "Maldives"
}, {
    "id": 141,
    "code": "ML",
    "country": "Mali"
}, {
    "id": 142,
    "code": "MT",
    "country": "Malte"
}, {
    "id": 143,
    "code": "MA",
    "country": "Maroc"
}, {
    "id": 144,
    "code": "MQ",
    "country": "Martinique"
}, {
    "id": 145,
    "code": "MU",
    "country": "Maurice"
}, {
    "id": 146,
    "code": "MR",
    "country": "Mauritanie"
}, {
    "id": 147,
    "code": "YT",
    "country": "Mayotte"
}, {
    "id": 148,
    "code": "MX",
    "country": "Mexique"
}, {
    "id": 149,
    "code": "FM",
    "country": "Micron\u00e9sie"
}, {
    "id": 150,
    "code": "MD",
    "country": "Moldavie"
}, {
    "id": 151,
    "code": "MC",
    "country": "Monaco"
}, {
    "id": 152,
    "code": "MN",
    "country": "Mongolie"
}, {
    "id": 153,
    "code": "ME",
    "country": "Mont\u00e9n\u00e9gro"
}, {
    "id": 154,
    "code": "MS",
    "country": "Montserrat"
}, {
    "id": 155,
    "code": "MZ",
    "country": "Mozambique"
}, {
    "id": 156,
    "code": "MM",
    "country": "Myanmar (Birmanie)"
}, {
    "id": 157,
    "code": "NA",
    "country": "Namibie"
}, {
    "id": 158,
    "code": "NR",
    "country": "Nauru"
}, {
    "id": 159,
    "code": "NP",
    "country": "N\u00e9pal"
}, {
    "id": 160,
    "code": "NI",
    "country": "Nicaragua"
}, {
    "id": 161,
    "code": "NE",
    "country": "Niger"
}, {
    "id": 162,
    "code": "NG",
    "country": "Nigeria"
}, {
    "id": 163,
    "code": "NU",
    "country": "Niue"
}, {
    "id": 164,
    "code": "NO",
    "country": "Norv\u00e8ge"
}, {
    "id": 165,
    "code": "NC",
    "country": "Nouvelle-Cal\u00e9donie"
}, {
    "id": 166,
    "code": "NZ",
    "country": "Nouvelle-Z\u00e9lande"
}, {
    "id": 167,
    "code": "OM",
    "country": "Oman"
}, {
    "id": 168,
    "code": "UG",
    "country": "Ouganda"
}, {
    "id": 169,
    "code": "UZ",
    "country": "Ouzb\u00e9kistan"
}, {
    "id": 170,
    "code": "PK",
    "country": "Pakistan"
}, {
    "id": 171,
    "code": "PW",
    "country": "Palaos"
}, {
    "id": 172,
    "code": "PA",
    "country": "Panama"
}, {
    "id": 173,
    "code": "PG",
    "country": "Papouasie-Nouvelle-Guin\u00e9e"
}, {
    "id": 174,
    "code": "PY",
    "country": "Paraguay"
}, {
    "id": 175,
    "code": "NL",
    "country": "Pays-Bas"
}, {
    "id": 176,
    "code": "BQ",
    "country": "Pays-Bas carib\u00e9ens"
}, {
    "id": 177,
    "code": "PE",
    "country": "P\u00e9rou"
}, {
    "id": 178,
    "code": "PH",
    "country": "Philippines"
}, {
    "id": 179,
    "code": "PL",
    "country": "Pologne"
}, {
    "id": 180,
    "code": "PF",
    "country": "Polyn\u00e9sie fran\u00e7aise"
}, {
    "id": 181,
    "code": "PR",
    "country": "Porto Rico"
}, {
    "id": 182,
    "code": "PT",
    "country": "Portugal"
}, {
    "id": 183,
    "code": "QA",
    "country": "Qatar"
}, {
    "id": 184,
    "code": "HK",
    "country": "R.A.S. chinoise de Hong Kong"
}, {
    "id": 185,
    "code": "MO",
    "country": "R.A.S. chinoise de Macao"
}, {
    "id": 186,
    "code": "CF",
    "country": "R\u00e9publique centrafricaine"
}, {
    "id": 187,
    "code": "DO",
    "country": "R\u00e9publique dominicaine"
}, {
    "id": 188,
    "code": "RO",
    "country": "Roumanie"
}, {
    "id": 189,
    "code": "GB",
    "country": "Royaume-Uni"
}, {
    "id": 190,
    "code": "RU",
    "country": "Russie"
}, {
    "id": 191,
    "code": "RW",
    "country": "Rwanda"
}, {
    "id": 192,
    "code": "EH",
    "country": "Sahara occidental"
}, {
    "id": 193,
    "code": "BL",
    "country": "Saint-Barth\u00e9lemy"
}, {
    "id": 194,
    "code": "KN",
    "country": "Saint-Christophe-et-Ni\u00e9v\u00e8s"
}, {
    "id": 195,
    "code": "SM",
    "country": "Saint-Marin"
}, {
    "id": 196,
    "code": "MF",
    "country": "Saint-Martin"
}, {
    "id": 197,
    "code": "SX",
    "country": "Saint-Martin (partie n\u00e9erlandaise)"
}, {
    "id": 198,
    "code": "PM",
    "country": "Saint-Pierre-et-Miquelon"
}, {
    "id": 199,
    "code": "VC",
    "country": "Saint-Vincent-et-les Grenadines"
}, {
    "id": 200,
    "code": "SH",
    "country": "Sainte-H\u00e9l\u00e8ne"
}, {
    "id": 201,
    "code": "LC",
    "country": "Sainte-Lucie"
}, {
    "id": 202,
    "code": "SV",
    "country": "Salvador"
}, {
    "id": 203,
    "code": "WS",
    "country": "Samoa"
}, {
    "id": 204,
    "code": "AS",
    "country": "Samoa am\u00e9ricaines"
}, {
    "id": 205,
    "code": "ST",
    "country": "Sao Tom\u00e9-et-Principe"
}, {
    "id": 206,
    "code": "SN",
    "country": "S\u00e9n\u00e9gal"
}, {
    "id": 207,
    "code": "RS",
    "country": "Serbie"
}, {
    "id": 208,
    "code": "SC",
    "country": "Seychelles"
}, {
    "id": 209,
    "code": "SL",
    "country": "Sierra Leone"
}, {
    "id": 210,
    "code": "SG",
    "country": "Singapour"
}, {
    "id": 211,
    "code": "SK",
    "country": "Slovaquie"
}, {
    "id": 212,
    "code": "SI",
    "country": "Slov\u00e9nie"
}, {
    "id": 213,
    "code": "SO",
    "country": "Somalie"
}, {
    "id": 214,
    "code": "SD",
    "country": "Soudan"
}, {
    "id": 215,
    "code": "SS",
    "country": "Soudan du Sud"
}, {
    "id": 216,
    "code": "LK",
    "country": "Sri Lanka"
}, {
    "id": 217,
    "code": "SE",
    "country": "Su\u00e8de"
}, {
    "id": 218,
    "code": "CH",
    "country": "Suisse"
}, {
    "id": 219,
    "code": "SR",
    "country": "Suriname"
}, {
    "id": 220,
    "code": "SJ",
    "country": "Svalbard et Jan Mayen"
}, {
    "id": 221,
    "code": "SY",
    "country": "Syrie"
}, {
    "id": 222,
    "code": "TJ",
    "country": "Tadjikistan"
}, {
    "id": 223,
    "code": "TW",
    "country": "Ta\u00efwan"
}, {
    "id": 224,
    "code": "TZ",
    "country": "Tanzanie"
}, {
    "id": 225,
    "code": "TD",
    "country": "Tchad"
}, {
    "id": 226,
    "code": "CZ",
    "country": "Tch\u00e9quie"
}, {
    "id": 227,
    "code": "TF",
    "country": "Terres australes fran\u00e7aises"
}, {
    "id": 228,
    "code": "IO",
    "country": "Territoire britannique de l\u2019oc\u00e9an Indien"
}, {
    "id": 229,
    "code": "PS",
    "country": "Territoires palestiniens"
}, {
    "id": 230,
    "code": "TH",
    "country": "Tha\u00eflande"
}, {
    "id": 231,
    "code": "TL",
    "country": "Timor oriental"
}, {
    "id": 232,
    "code": "TG",
    "country": "Togo"
}, {
    "id": 233,
    "code": "TK",
    "country": "Tokelau"
}, {
    "id": 234,
    "code": "TO",
    "country": "Tonga"
}, {
    "id": 235,
    "code": "TT",
    "country": "Trinit\u00e9-et-Tobago"
}, {
    "id": 236,
    "code": "TN",
    "country": "Tunisie"
}, {
    "id": 237,
    "code": "TM",
    "country": "Turkm\u00e9nistan"
}, {
    "id": 238,
    "code": "TR",
    "country": "Turquie"
}, {
    "id": 239,
    "code": "TV",
    "country": "Tuvalu"
}, {
    "id": 240,
    "code": "UA",
    "country": "Ukraine"
}, {
    "id": 241,
    "code": "UY",
    "country": "Uruguay"
}, {
    "id": 242,
    "code": "VU",
    "country": "Vanuatu"
}, {
    "id": 243,
    "code": "VE",
    "country": "Venezuela"
}, {
    "id": 244,
    "code": "VN",
    "country": "Vi\u00eat Nam"
}, {
    "id": 245,
    "code": "WF",
    "country": "Wallis-et-Futuna"
}, {
    "id": 246,
    "code": "YE",
    "country": "Y\u00e9men"
}, {
    "id": 247,
    "code": "ZM",
    "country": "Zambie"
}, {
    "id": 248,
    "code": "ZW",
    "country": "Zimbabwe"
}
  ]
  constructor() { }
}

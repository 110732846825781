import { Component, OnInit } from '@angular/core';

import { ActivitiesService } from 'app/layout/components/navbar/navbar-activities/activities.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}


@Component({
  selector: 'app-navbar-activities',
  templateUrl: './navbar-activities.component.html',
  styleUrls: ['./navbar-activities.component.scss']
})
export class NavbarActivitiesComponent implements OnInit {

   // Public
   public notifications: notification;

   constructor(private _activitiesService: ActivitiesService) {}
 
   // Lifecycle Hooks
   // -----------------------------------------------------------------------------------------------------
 
   /**
    * On init
    */
   ngOnInit(): void {
     this._activitiesService.onApiDataChange.subscribe(res => {
       this.notifications = res;
     });
   }
 }
 
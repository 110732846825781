import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DetailsUserService implements Resolve<any> {
    

  rows: any;
  detailsUser: BehaviorSubject<any>;
  liste_abonnements: BehaviorSubject<any>;

private _BackendUrl = environment._BackendUrl;


  constructor(private _httpClient: HttpClient) {
    this.detailsUser = new BehaviorSubject({});
    this.liste_abonnements = new BehaviorSubject({});
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let currentId = Number(route.paramMap.get('id'));
    return new Promise<void>((resolve, reject) => {
      Promise.all([ this.get_User_details(currentId), this.get_liste_abonnements()]).then(() => {
        resolve();
      }, reject);
    });
  }


  ajouter_abonnement(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"ajouter_abonnement",data);
  }  
  
  modifier_abonnement(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"modifier_abonnement",data);
  }  
  

  get_User_details(id: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<any>(this._BackendUrl+"get_user_details",{id:id}).subscribe((response: any) => {
        let rows = response;
          this.detailsUser.next(rows);
          resolve(rows);
      }, reject);
    });
  }


  get_liste_abonnements(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<any>(this._BackendUrl+"get_liste_abonnements_admin").subscribe((response: any) => {
        let rows = response;
          this.liste_abonnements.next(rows);
          resolve(rows);
      }, reject);
    });
  }


}


import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PaysService } from 'app/service/pays.service';
import { ReglagesPaiementsService } from 'app/service/reglages-paiements.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { User } from 'app/auth/models/user';
import { MyPermissionsService } from 'app/auth/service/my-permissions.service';
import { DetailsUserService } from './details-user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { log } from 'console';


@Component({
  selector: 'app-details-user',
  templateUrl: './details-user.component.html',
  styleUrls: ['./details-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsUserComponent implements OnInit {

 
  private _BackendUrl = environment._BackendUrl;
  private _unsubscribeAll: Subject<any>;
  apiData: any;
  currency = 'EUR'

  id:any
  pays: any;
  methodes_paiement: { id: number; nom: string; }[];



  
  permissions: any;
  apiDataAb: any;


  
  current_day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();
  current_month = new Date().getMonth()+ 1 < 10 ? '0' + (new Date().getMonth()+ 1) : new Date().getMonth()+ 1;
  current_year = new Date().getFullYear();
  current_date = this.current_year + '-'+this.current_month + '-'+ this.current_day



  // ng2-flatpickr options
  public dateOptions = {
    altInput: true,
    disableMobile: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    defaultDate: [this.current_date],
    altFormat: 'Y-n-j'
  };


  submittedAbonnement = false;
  formAbonnement: FormGroup;
  liste_abonnements: any;


  
  submittedEditAbonnement = false;
  formEditAbonnement: FormGroup;

  
  
  constructor(
    private modalService: NgbModal,
    private _detailsUserService: DetailsUserService,
    private activatedRoute: ActivatedRoute,
    private _coreSidebarService: CoreSidebarService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
  ) {
    this._unsubscribeAll = new Subject();
  }


  
  ngOnInit(): void {
  
    
      this.id = Number(this.activatedRoute.snapshot.paramMap.get("id"));  
      
      this._detailsUserService.liste_abonnements.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
        this.liste_abonnements = response;
      });

      this._detailsUserService.detailsUser.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
        this.apiData = response.results.client;
        this.apiDataAb = response.results.abonnement;
      });

      
      this.formAbonnement = this._formBuilder.group({
        selectedAbonnement:[''],
        type: ['', [Validators.required]],
        nom: ['', [Validators.required]],
        debut: ['', [Validators.required]],
        duree: ['', [Validators.required]],
        listeAbonnementId: ['', [Validators.required]],
        mensuel: ['', [Validators.required]],
        nb_devis: ['', [Validators.required]],
        nb_commandes_clients: ['', [Validators.required]],
        nb_bon_livraisons: ['', [Validators.required]],
        nb_factures: ['', [Validators.required]],
        nb_avoirs: ['', [Validators.required]],
        nb_factures_fournisseurs: ['', [Validators.required]],
        nb_users: ['', [Validators.required]],
        nb_entreprises: ['', [Validators.required]],
        nb_produits: ['', [Validators.required]],
        nb_clients: ['', [Validators.required]],
        nb_fournisseurs: ['', [Validators.required]],
        plan_mensuel_prix_mois: ['', [Validators.required]],
        plan_annuel_prix_mois: ['', [Validators.required]],
        plan_annuel_prix_an: ['', [Validators.required]],        
        superUserId: [parseInt(this.id), [Validators.required]]
      });

      this.formEditAbonnement = this._formBuilder.group({
        selectedAbonnement:[''],
       
        debut: ['', [Validators.required]],
        duree: ['', [Validators.required]],
        mensuel: ['', [Validators.required]],
        nb_devis: ['', [Validators.required]],
        nb_commandes_clients: ['', [Validators.required]],
        nb_bon_livraisons: ['', [Validators.required]],
        nb_factures: ['', [Validators.required]],
        nb_avoirs: ['', [Validators.required]],
        nb_factures_fournisseurs: ['', [Validators.required]],
        nb_users: ['', [Validators.required]],
        nb_entreprises: ['', [Validators.required]],
        nb_produits: ['', [Validators.required]],
        nb_clients: ['', [Validators.required]],
        nb_fournisseurs: ['', [Validators.required]],
        plan_mensuel_prix_mois: ['', [Validators.required]],
        plan_annuel_prix_mois: ['', [Validators.required]],
        plan_annuel_prix_an: ['', [Validators.required]],
        superUserId: [parseInt(this.id), [Validators.required]],
        id: ['']
      });


  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  modalOpenDanger(modalDanger) {
    this.modalService.open(modalDanger, {
      windowClass: 'modal modal-danger'
    });
  }


  changer_type_ab(){
      if(this.formAbonnement.value.mensuel == "true") this.fAbonnement.duree.patchValue(30)
      if(this.formAbonnement.value.mensuel == "false") this.fAbonnement.duree.patchValue(365)
  }

  changer_abonnement(){
    
    let ab = this.liste_abonnements.filter(row => { return row.id == this.formAbonnement.value.selectedAbonnement});
    if( ab.length == 1){

      this.fAbonnement.nb_devis.patchValue(ab[0].nb_devis)
      this.fAbonnement.nb_commandes_clients.patchValue(ab[0].nb_commandes_clients)
      this.fAbonnement.nb_bon_livraisons.patchValue(ab[0].nb_bon_livraisons)
      this.fAbonnement.nb_factures.patchValue(ab[0].nb_factures)
      this.fAbonnement.nb_avoirs.patchValue(ab[0].nb_avoirs)
      this.fAbonnement.nb_factures_fournisseurs.patchValue(ab[0].nb_factures_fournisseurs)
      this.fAbonnement.nb_users.patchValue(ab[0].nb_users)
      this.fAbonnement.nb_entreprises.patchValue(ab[0].nb_entreprises)
      this.fAbonnement.nb_produits.patchValue(ab[0].nb_produits)
      this.fAbonnement.nb_clients.patchValue(ab[0].nb_clients)
      this.fAbonnement.nb_fournisseurs.patchValue(ab[0].nb_fournisseurs)
      this.fAbonnement.plan_mensuel_prix_mois.patchValue(ab[0].plan_mensuel_prix_mois)
      this.fAbonnement.plan_annuel_prix_mois.patchValue(ab[0].plan_annuel_prix_mois)
      this.fAbonnement.plan_annuel_prix_an.patchValue(ab[0].plan_annuel_prix_an)
      this.fAbonnement.type.patchValue(ab[0].type)
      this.fAbonnement.nom.patchValue(ab[0].nom)
      this.fAbonnement.listeAbonnementId.patchValue(ab[0].id)
      this.fAbonnement.superUserId.patchValue(parseInt(this.id))

    }

  }


  selectAbonnement(id){
    let ab = this.apiDataAb.filter(row => { return row.id == id});
    if( ab.length == 1){

      console.log(ab[0]);
      this.formEditAbonnement.reset();

      this.fEditAbonnement.nb_devis.patchValue(ab[0].nb_devis)
      this.fEditAbonnement.nb_commandes_clients.patchValue(ab[0].nb_commandes_clients)
      this.fEditAbonnement.nb_bon_livraisons.patchValue(ab[0].nb_bon_livraisons)
      this.fEditAbonnement.nb_factures.patchValue(ab[0].nb_factures)
      this.fEditAbonnement.nb_avoirs.patchValue(ab[0].nb_avoirs)
      this.fEditAbonnement.nb_factures_fournisseurs.patchValue(ab[0].nb_factures_fournisseurs)
      this.fEditAbonnement.nb_users.patchValue(ab[0].nb_users)
      this.fEditAbonnement.nb_entreprises.patchValue(ab[0].nb_entreprises)
      this.fEditAbonnement.nb_produits.patchValue(ab[0].nb_produits)
      this.fEditAbonnement.nb_clients.patchValue(ab[0].nb_clients)
      this.fEditAbonnement.nb_fournisseurs.patchValue(ab[0].nb_fournisseurs)
      this.fEditAbonnement.plan_mensuel_prix_mois.patchValue(ab[0].plan_mensuel_prix_mois)
      this.fEditAbonnement.plan_annuel_prix_mois.patchValue(ab[0].plan_annuel_prix_mois)
      this.fEditAbonnement.plan_annuel_prix_an.patchValue(ab[0].plan_annuel_prix_an)
      this.fEditAbonnement.superUserId.patchValue(parseInt(this.id))
      this.fEditAbonnement.debut.patchValue(this.traite_date(ab[0].createdAt))
      this.fEditAbonnement.selectedAbonnement.patchValue(ab[0].listeAbonnementId)
      this.fEditAbonnement.mensuel.patchValue((ab[0].mensuel).toString())
      this.fEditAbonnement.duree.patchValue(ab[0].duree)
      this.fEditAbonnement.id.patchValue(id)

    }
    
  }


  traite_date(d){
    const date = new Date(d);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; 
  

  }

  
  getPays(id){
    let p =  this.pays.filter(row => { return row.id == id})

    if(p?.length > 0) return p[0].country

    return ''
  }
  

  get_methodes_paiement(m){
    let mp = this.methodes_paiement.filter(row => { return row.id == m});
    if( mp.length>0){
      return mp[0].nom
    }
    return  ''
  }
  

  
  
  get fAbonnement() {
    return this.formAbonnement.controls;
  }
  get fEditAbonnement() {
    return this.formEditAbonnement.controls;
  }


  
  ajouter_abonnement() {

    this.submittedAbonnement = true;


    if (this.formAbonnement.invalid) {
      return;
    }

    this._detailsUserService.ajouter_abonnement(this.formAbonnement.value).pipe(first()).subscribe(
      res => {
          if(res.msg == "success"){
            this.toastr.success('Abonnement ajouté avec succés.', 'Succés!', {
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });
            this.toggleSidebar('new-abonnement-sidebar');
            this._detailsUserService.get_User_details(this.id).then(()=>{
             
              this.formAbonnement.reset();
              this.submittedAbonnement = false;
            })
           
          }
      },
      err =>{
        console.log(err);
        
        this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
          toastClass: 'toast ngx-toastr',
          closeButton: true
        });
      }
    )

  }



  modifier_abonnement() {

    this.submittedEditAbonnement = true;

    console.log(this.formEditAbonnement.value);
    

    if (this.formEditAbonnement.invalid) {
      return;
    }

    this._detailsUserService.modifier_abonnement(this.formEditAbonnement.value).pipe(first()).subscribe(
      res => {
          if(res.msg == "success"){
            this.toastr.success('Abonnement modifié avec succés.', 'Succés!', {
              toastClass: 'toast ngx-toastr',
              closeButton: true
            });
            this.toggleSidebar('edit-abonnement-sidebar');
            this._detailsUserService.get_User_details(this.id).then(()=>{             
              this.formEditAbonnement.reset();
              this.submittedEditAbonnement = false;
            })
           
          }
      },
      err =>{
        console.log(err);
        
        this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
          toastClass: 'toast ngx-toastr',
          closeButton: true
        });
      }
    )

  }


  // supprimer(modalDanger){
  //   this._detailsUserService.supprimer_client({id:this.id}).pipe(first()).subscribe(
  //     res => {
  //         if(res.msg == "success"){
  //           this.toastr.success('Client supprimée avec succés.', 'Succés!', {
  //             toastClass: 'toast ngx-toastr',
  //             closeButton: true
  //           });
  //           this.modalService.dismissAll()
  //           this.router.navigate(["/client/list"]);
  //         }
  //     },
  //     err =>{
  //       console.log(err);
        
  //       this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
  //         toastClass: 'toast ngx-toastr',
  //         closeButton: true
  //       });
  //     }
  //   )
  // }




}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';


import { AuthLoginV1Component } from 'app/main/authentication/auth-login-v1/auth-login-v1.component';

// import { AuthRegisterV1Component } from 'app/main/authentication/auth-register-v1/auth-register-v1.component';

// routing
const routes: Routes = [
  {
    path: 'sign-in',
    component: AuthLoginV1Component
  },
  // {
  //   path: 'sign-up',
  //   component: AuthRegisterV1Component
  // }
];

@NgModule({
  declarations: [
    AuthLoginV1Component,
    // AuthRegisterV1Component
  ],
  imports: [CommonModule, RouterModule.forChild(routes), NgbModule, FormsModule, ReactiveFormsModule, CoreCommonModule],
  providers:[]
})
export class AuthenticationModule {}

<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
  ></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input
      class="form-control input"
      type="text"
      placeholder="Explore Ventexy..."
      tabindex="-1"
      data-search="search"
      (keyup)="searchUpdate($event)"
      [(ngModel)]="searchText"
      #openSearch
    /><button class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

    <!-- Search List -->
    <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
      <!-- Pages -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Factures clients</h6></a>
      </li>
      <ng-container *ngIf="factures.length; else noResultsPages">
        <li
          class="auto-suggestion"
          *ngFor="let page of factures |  slice: 0:5; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/sales/invoice/preview/'+page.id)"
            routerLink="/sales/invoice/preview/{{page.id}}"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="'file'" class="mr-75"></i><span>{{ page.prefixe + page.reference }}</span>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ page.date }}</small>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPages>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->



      <!-- Pages -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Devis</h6></a>
      </li>
      <ng-container *ngIf="devis.length; else noResultsPagesdevis">
        <li
          class="auto-suggestion"
          *ngFor="let page of devis |  slice: 0:5; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/sales/estimate/preview/'+page.id)"
            routerLink="/sales/estimate/preview/{{page.id}}"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="'file'" class="mr-75"></i><span>{{ page.prefixe + page.reference }}</span>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ page.date }}</small>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPagesdevis>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->



       <!-- Pages -->
       <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Bons de commandes clients</h6></a>
      </li>
      <ng-container *ngIf="commande_client.length; else noResultsPagescommandeclient">
        <li
          class="auto-suggestion"
          *ngFor="let page of commande_client |  slice: 0:5; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/sales/order/preview/'+page.id)"
            routerLink="/sales/order/preview/{{page.id}}"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="'file'" class="mr-75"></i><span>{{ page.prefixe + page.reference }}</span>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ page.date }}</small>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPagescommandeclient>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->




      <!-- Pages -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Bons de livraison</h6></a>
      </li>
      <ng-container *ngIf="bon_livraison.length; else noResultsPagesbon_livraison">
        <li
          class="auto-suggestion"
          *ngFor="let page of bon_livraison |  slice: 0:5; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/sales/delivery/preview/'+page.id)"
            routerLink="/sales/delivery/preview/{{page.id}}"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="'file'" class="mr-75"></i><span>{{ page.prefixe + page.reference }} <small class="search-data-size ml-1 text-muted">({{ page.reference_externe }})</small></span>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ page.date }}</small>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPagesbon_livraison>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->



      <!-- Pages -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Factures fournisseurs</h6></a>
      </li>
      <ng-container *ngIf="facture_achat.length; else noResultsPagesfacture_achat">
        <li
          class="auto-suggestion"
          *ngFor="let page of facture_achat |  slice: 0:5; let i = index"
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/purchases/invoice/preview/'+page.id)"
            routerLink="/purchases/invoice/preview/{{page.id}}"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="'file'" class="mr-75"></i><span>{{ page.prefixe + page.reference }}</span>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ page.date }}</small>
          </a>
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPagesfacture_achat>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->


      <!-- Members -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Clients</h6></a>
      </li>
      <ng-container *ngIf="clients.length; else noResultsMembers">
        <li class="auto-suggestion" *ngFor="let contact of clients | slice: 0:4">
          <a class="d-flex align-items-center justify-content-between py-50 w-100" href="javascript:void(0)"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/client/details/'+contact.id)"
            routerLink="/client/details/{{contact.id}}"
            ><div class="d-flex align-items-center">
              <!-- <div class="avatar mr-75"><img src="{{ _BackendUrl +'img/clients/'+ contact.avatar }}" alt="png" height="32" /></div> -->
              <div  class="avatar mr-75" *ngIf="contact.avatar?.length > 0; else customAvatarcl">
                <img
                  class="rounded-circle mr-1"
                  src="{{ _BackendUrl +'img/clients/'+ contact.avatar }}"
                  height="32"
                  width="32"
                  alt="datatable-avatar"
                />
              </div>
              <ng-template #customAvatarcl>
                <div
                  class="avatar mr-1 ml-0"
                  [ngClass]="{
                    'bg-light-primary': ['a','b','c','d','e','0','5'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-success': ['f','g','h','i','j','1','6'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-danger': ['k','l','m','n','o','2','7'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-warning': ['p','q','r','s','t','3','8'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((contact.nom[0]).toLowerCase())
                  }"
                >
                  <div class="avatar-content">{{ (contact.nom |uppercase) | initials }}</div>
                </div>
              </ng-template>
              
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.nom }}</p>
                <small class="text-muted"><span *ngIf="contact.email" class="mr-1">{{ contact.email }}</span> <span *ngIf="contact.telephone">{{ contact.telephone }}</span></small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ contact.adresse }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsMembers>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Members -->


      <!-- Members -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Fournisseurs</h6></a>
      </li>
      <ng-container *ngIf="fournisseurs.length; else noResultsMembersfr">
        <li class="auto-suggestion" *ngFor="let contact of fournisseurs | slice: 0:4">
          <a class="d-flex align-items-center justify-content-between py-50 w-100" href="javascript:void(0)"
            (click)="toggleSearch()"
            (keyup.enter)="navigate('/supplier/details/'+contact.id)"
            routerLink="/supplier/details/{{contact.id}}"
            ><div class="d-flex align-items-center">
              <!-- <div class="avatar mr-75"><img src="{{ _BackendUrl +'img/fournisseurs/'+ contact.avatar }}" alt="png" height="32" /></div> -->
              <div  class="avatar mr-75" *ngIf="contact.avatar?.length > 0; else customAvatarfr">
                <img
                  class="rounded-circle mr-1"
                  src="{{ _BackendUrl +'img/fournisseurs/'+ contact.avatar }}"
                  height="32"
                  width="32"
                  alt="datatable-avatar"
                />
              </div>
              <ng-template #customAvatarfr>
                <div
                  class="avatar mr-1 ml-0"
                  [ngClass]="{
                    'bg-light-primary': ['a','b','c','d','e','0','5'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-success': ['f','g','h','i','j','1','6'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-danger': ['k','l','m','n','o','2','7'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-warning': ['p','q','r','s','t','3','8'].includes((contact.nom[0]).toLowerCase()),
                    'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((contact.nom[0]).toLowerCase())
                  }"
                >
                  <div class="avatar-content">{{ (contact.nom |uppercase) | initials }}</div>
                </div>
              </ng-template>
              
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.nom }}</p>
                <small class="text-muted"><span *ngIf="contact.email" class="mr-1">{{ contact.email }}</span> <span *ngIf="contact.telephone">{{ contact.telephone }}</span></small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ contact.adresse }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsMembersfr>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Members -->


    </ul>
    <!--/ Search List -->
  </div>
</li>

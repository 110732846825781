import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class VisiteursSitewebService implements Resolve<any> {
    

  rows: any;
  statistiques: BehaviorSubject<any>;

private _BackendUrl = environment._BackendUrl;


  constructor(private _httpClient: HttpClient) {
    this.statistiques = new BehaviorSubject({});
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([ this.get_statistiques()]).then(() => {
        resolve();
      }, reject);
    });
  }

  
  get_nbr_statiqtiques_website(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"get_nbr_statiqtiques_website");
  }

  
  get_statistiques(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<any>(this._BackendUrl+"get_statiqtiques_website").subscribe((response: any) => {
        let rows = response;
          this.statistiques.next(rows);
          resolve(rows);
      }, reject);
    });
  }


}


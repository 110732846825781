import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ListCompaniesService implements Resolve<any> {
    

  rows: any;
  list: BehaviorSubject<any>;

private _BackendUrl = environment._BackendUrl;


  constructor(private _httpClient: HttpClient) {
    this.list = new BehaviorSubject({});
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([ 
        this.get_companies()
      ]).then(() => {
        resolve();
      }, reject);
    });
  }


  ajouter_produit(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"ajouter_produit",data);
  }


  details_user(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"details_user",data);
  }
  supprimer_client_par_admin(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"supprimer_client_par_admin",data);
  }

  supprimer_demandes_demo(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"supprimer_demandes_demo",data);
  }
  update_demandes_demo(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"update_demandes_demo",data);
  }
  get_nbr_statiqtiques_website(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"get_nbr_statiqtiques_website");
  }


  liste_demandes_demo(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"liste_demandes_demo");
  }
  get_demandes_devis_abonnement(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"get_demandes_devis_abonnement");
  }
  supprimer_demandes_devis(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"supprimer_demandes_devis_abonnement",data);
  }
  update_demandes_devis(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"update_demandes_devis_abonnement",data);
  }
  mailMultiple(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"envoyer_multiple_email_inscription",data);
  }

  get_companies(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<any>(this._BackendUrl+"get_companies").subscribe((response: any) => {
        let rows = response;
          this.list.next(rows);
          resolve(rows);
      }, reject);
    });
  }


}


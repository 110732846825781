import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { VisiteursSitewebService } from './visiteurs-siteweb.service';
import { PaysService } from 'app/service/pays.service';



@Component({
  selector: 'app-visiteurs-siteweb',
  templateUrl: './visiteurs-siteweb.component.html',
  styleUrls: ['./visiteurs-siteweb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisiteursSitewebComponent implements OnInit {

   // Private
   private _unsubscribeAll: Subject<any>;
   private tempData = [];
   public rows: any;
   public exportCSVData;
   public selected = [];

   public SelectionType = SelectionType;

    public selectedOption = 100;
    public searchValue = '';

    public ColumnMode = ColumnMode;

      
    public selectedStatus = [];
    public tempFilterData;
    public previousStatusFilter = '';
    @ViewChild(DatatableComponent) table: DatatableComponent;
  statistiques: any;
  total_visiteurs: number;
  pays = []


  constructor(
    private _visiteursSitewebService: VisiteursSitewebService,
    private _pays:PaysService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this._visiteursSitewebService.statistiques.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      this.rows = response;
    });

    this._visiteursSitewebService.get_nbr_statiqtiques_website().subscribe(response => {
      this.statistiques = response[0];
      this.total_visiteurs = parseInt(this.statistiques?.count_facebook) + parseInt(this.statistiques?.count_google) + parseInt(this.statistiques?.count_others)
    });

    this.pays = this._pays.pays
    
  }




  estAujourdhui(derniereConnexion: Date): boolean {
    const aujourdhui = new Date();
    const derniereConnexionDate = new Date(derniereConnexion);
  
    // Récupère la date d'aujourd'hui sans l'heure
    const aujourdhuiSansHeure = new Date(aujourdhui.getFullYear(), aujourdhui.getMonth(), aujourdhui.getDate());
  
    // Récupère la date de la dernière connexion sans l'heure
    const derniereConnexionSansHeure = new Date(derniereConnexionDate.getFullYear(), derniereConnexionDate.getMonth(), derniereConnexionDate.getDate());
  
    
    return aujourdhuiSansHeure.getTime() === derniereConnexionSansHeure.getTime();
  
  
  }

  
  getPaysName(id){
    if( id == '') return ''
    let p =  this.pays.filter(row => { return row.code == id})
  
    if(p?.length > 0) return p[0].country
  
    return ''
  }



  filterByStatus(event) {
    const filter = event ? event.value : 0;
    
    this.previousStatusFilter = filter;
    this.tempFilterData = this.filterRows(filter);
    this.rows = this.tempFilterData;
  }

  filterRows(statusFilter): any[] {
    // Reset search on select change
    this.searchValue = '';

    // statusFilter = parseInt(statusFilter)

    return this.tempData.filter(row => {
      console.log(row);
      
      const isPartialNameMatch = row.status.indexOf(statusFilter) !== -1 || !statusFilter;
      return isPartialNameMatch;
    });
  }


  // ta3tik les données mta3 el ligne eli 7atit fou9ou souris fl datatable
  onActivate(event) {
  }

  onSelect({ selected }) {
    this.selected = selected;  
    
  }


  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    
  }
  
}

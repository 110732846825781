import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoreMenuService {
  currentUser: User;
  onItemCollapsed: Subject<any>;
  onItemCollapseToggled: Subject<any>;

  // Private
  private _onMenuRegistered: BehaviorSubject<any>;
  private _onMenuUnregistered: BehaviorSubject<any>;
  private _onMenuChanged: BehaviorSubject<any>;
  private _currentMenuKey: string;
  private _registry: { [key: string]: any } = {};


    // Private
    private _onPermissionRegistered: BehaviorSubject<any>;
    private _onPermissionUnregistered: BehaviorSubject<any>;
    private _onPermissionChanged: BehaviorSubject<any>;
    private _currentPermissionKey: string;
    private _registryPermission: { [key: string]: any } = {};


  private _BackendUrl = environment._BackendUrl;


  constructor(private _httpClient: HttpClient, private _router: Router, private _authenticationService: AuthenticationService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    // Set defaults
    this.onItemCollapsed = new Subject();
    this.onItemCollapseToggled = new Subject();

    // Set private defaults
    this._currentMenuKey = null;
    this._onMenuRegistered = new BehaviorSubject(null);
    this._onMenuUnregistered = new BehaviorSubject(null);
    this._onMenuChanged = new BehaviorSubject(null);


    // Set private defaults
    this._currentPermissionKey = null;
    this._onPermissionRegistered = new BehaviorSubject(null);
    this._onPermissionUnregistered = new BehaviorSubject(null);
    this._onPermissionChanged = new BehaviorSubject(null);
  }


  get onMenuRegistered(): Observable<any> {
    return this._onMenuRegistered.asObservable();
  }


  get onMenuUnregistered(): Observable<any> {
    return this._onMenuUnregistered.asObservable();
  }


  get onMenuChanged(): Observable<any> {
    return this._onMenuChanged.asObservable();
  }


  register(key, menu): void {
    // Confirm if the key already used
    if (this._registry[key]) {
      console.error(`Menu with the key '${key}' already exists. Either unregister it first or use a unique key.`);

      return;
    }

    // Add to registry
    this._registry[key] = menu;

    // Notify subject
    this._onMenuRegistered.next([key, menu]);
  }


  unregister(key): void {
    // Confirm if the menu exists
    if (!this._registry[key]) {
      console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);
    }

    // Unregister sidebar
    delete this._registry[key];

    // Notify subject
    this._onMenuUnregistered.next(key);
  }


  getMenu(key): any {
    // Confirm if the menu exists
    if (!this._registry[key]) {
      console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    // Return sidebar
    return this._registry[key];
  }


  getCurrentMenu(): any {
    if (!this._currentMenuKey) {
      console.warn(`The current menu is not set.`);

      return;
    }

    return this.getMenu(this._currentMenuKey);
  }


  setCurrentMenu(key): void {
    // Confirm if the sidebar exists
    if (!this._registry[key]) {
      console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    // Set current menu key
    this._currentMenuKey = key;

    // Notify subject
    this._onMenuChanged.next(key);
  }




  // permission ###########################""



  
  get onPermissionRegistered(): Observable<any> {
    return this._onPermissionRegistered.asObservable();
  }


  get onPermissionUnregistered(): Observable<any> {
    return this._onPermissionUnregistered.asObservable();
  }


  get onPermissionChanged(): Observable<any> {
    return this._onPermissionChanged.asObservable();
  }


  registerPermission(key, menu): void {
    // Confirm if the key already used
    if (this._registryPermission[key]) {
      console.error(`Permission with the key '${key}' already exists. Either unregister it first or use a unique key.`);

      return;
    }

    // Add to registry
    this._registryPermission[key] = menu;

    // Notify subject
    this._onPermissionRegistered.next([key, menu]);
  }


  unregisterPermission(key): void {
    // Confirm if the menu exists
    if (!this._registryPermission[key]) {
      console.warn(`Permission with the key '${key}' doesn't exist in the registry.`);
    }

    // Unregister sidebar
    delete this._registryPermission[key];

    // Notify subject
    this._onPermissionUnregistered.next(key);
  }


  getPermission(key): any {
    // Confirm if the menu exists
    if (!this._registryPermission[key]) {
      console.warn(`Permission with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    // Return sidebar
    return this._registryPermission[key];
  }


  getCurrentPermission(): any {
    if (!this._currentPermissionKey) {
      console.warn(`The current menu is not set.`);

      return;
    }

    return this.getPermission(this._currentPermissionKey);
  }


  setCurrentPermission(key): void {
    // Confirm if the sidebar exists
    if (!this._registryPermission[key]) {
      console.warn(`Permission with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    // Set current menu key
    this._currentPermissionKey = key;

    // Notify subject
    this._onPermissionChanged.next(key);
  }


  

  get_menu_settings(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"get_menu_settings");
  }

  get_my_permissions(): Observable<any> {
    return this._httpClient.get<any>(this._BackendUrl+"get_my_permissions");
  }
  

}

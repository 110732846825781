import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { ActivatedRoute, Router } from '@angular/router';

import { SocialUser } from "angularx-social-login";

@Component({
  selector: 'app-auth-login-v1',
  templateUrl: './auth-login-v1.component.html',
  styleUrls: ['./auth-login-v1.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AuthLoginV1Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public submitted = false;
  public passwordTextType: boolean;

  public loading = false;
  public returnUrl: string = '/';
  public error = '';
  user: SocialUser;
  loggedIn: boolean;
  // Private
  private _unsubscribeAll: Subject<any>;
  FirstLogin: boolean;

  constructor(private _coreConfigService: CoreConfigService, 
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {

    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }



  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }


  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }




  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

      // Login
      this.loading = true;

      // setTimeout(()=>{     
        

          this._authenticationService
          .login(this.f.email.value, this.f.password.value)
          .pipe()
          .subscribe(
            data => {
              this.loading = false;
              if(data == 'echec'){
                return this.error = 'Email ou mot de passe incorrect!, Réessayez'
              }

              location.href='/'
            },
            error => {
              this.error = error;
              this.loading = false;
            }
          );

      //  }, 1000);

     

  }



  ngOnInit(): void {

   
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });


    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';


    
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

 

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">



      <ng-template #modalCompleteAccount let-modal>
        <div class="modal-header bg-transparent">
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body pb-5 px-sm-4 mx-50" tabindex="0" ngbAutofocus>
          <h1 class="address-title text-center mb-1" id="addNewAddressTitle">Add New Address</h1>
          <p class="address-subtitle text-center mb-2 pb-75">Add address for billing address</p>
          
          <div id="stepper2" class="bs-stepper vertical vertical-wizard-example">
            <div class="bs-stepper-header">
              <div class="step" data-target="#account-details-vertical">
                <button type="button" class="step-trigger">
                  <span class="bs-stepper-box">
                    <i data-feather="book" class="font-medium-3"></i>
                  </span>
                  <span class="bs-stepper-label">
                    <span class="bs-stepper-title">Company Info</span>
                    <span class="bs-stepper-subtitle">Setup Company Info</span>
                  </span>
                </button>
              </div>
              <div class="step" data-target="#personal-info-vertical">
                <button type="button" class="step-trigger">
                  <span class="bs-stepper-box">
                    <i data-feather="user" class="font-medium-3"></i>
                  </span>
                  <span class="bs-stepper-label">
                    <span class="bs-stepper-title">Personal Info</span>
                    <span class="bs-stepper-subtitle">Add Personal Info</span>
                  </span>
                </button>
              </div>
              <div class="step" data-target="#address-step-vertical">
                <button type="button" class="step-trigger">
                  <span class="bs-stepper-box">
                    <i data-feather="map-pin" class="font-medium-3"></i>
                  </span>
                  <span class="bs-stepper-label">
                    <span class="bs-stepper-title">Address</span>
                    <span class="bs-stepper-subtitle">Add Address</span>
                  </span>
                </button>
              </div>
              <div class="step" data-target="#address2-step-vertical">
                <button type="button" class="step-trigger">
                  <span class="bs-stepper-box">
                    <i data-feather="map-pin" class="font-medium-3"></i>
                  </span>
                  <span class="bs-stepper-label">
                    <span class="bs-stepper-title">Address</span>
                    <span class="bs-stepper-subtitle">Add Address</span>
                  </span>
                </button>
              </div>
              <div class="step" data-target="#social-links-vertical">
                <button type="button" class="step-trigger">
                  <span class="bs-stepper-box">
                    <i data-feather="check" class="font-medium-3"></i>
                  </span>
                  <span class="bs-stepper-label">
                    <span class="bs-stepper-title">Submit</span>
                    <span class="bs-stepper-subtitle">Finish your account</span>
                  </span>
                </button>
              </div>
            </div>
            <div class="bs-stepper-content">
              <div id="account-details-vertical" class="content">
                <h5>Nom de la société</h5>
                <input class="form-control" type="text" placeholder="Ventexy Admin" />
  
                <h5 class="mt-2 pt-1">Category</h5> 
                <ul class="list-group list-group-flush">
                  <li class="list-group-item border-0 px-0">
                      <label for="createAppCrm" class="d-flex cursor-pointer">
                          <span class="avatar avatar-tag bg-light-info mr-1">
                              <i data-feather="globe" class="font-medium-5"></i>
                          </span>
                          <span class="d-flex align-items-center justify-content-between flex-grow-1">
                              <span class="mr-1">
                                  <span class="h5 d-block fw-bolder">Entreprise</span>
                                  <span>Scales with Any Business</span>
                              </span>
                              <span class="custom-control custom-radio">
                                  <input class="custom-control-input" checked id="createAppCrm" type="radio" name="categoryRadio" />
                                  <label class="custom-control-label" for="createAppCrm"></label>
                              </span>
                          </span>
                      </label>
                  </li>
                  <li class="list-group-item border-0 px-0">
                      <label for="createAppEcommerce" class="d-flex cursor-pointer">
                          <span class="avatar avatar-tag bg-light-success mr-1">
                              <i data-feather="user" class="font-medium-5"></i>
                          </span>
                          <span class="d-flex align-items-center justify-content-between flex-grow-1">
                              <span class="mr-1">
                                  <span class="h5 d-block fw-bolder">Particulier</span>
                                  <span>Grow Your Business With App</span>
                              </span>
                              <span class="custom-control custom-radio">
                                <input class="custom-control-input" id="createAppEcommerce" type="radio" name="categoryRadio" />
                                <label class="custom-control-label" for="createAppEcommerce"></label>
                              </span>
                          </span>
                      </label>
                  </li>
                  <li class="list-group-item border-0 px-0">
                      <label for="createAppOnlineLearning" class="d-flex cursor-pointer">
                          <span class="avatar avatar-tag bg-light-danger mr-1">
                              <i data-feather="briefcase" class="font-medium-5"></i>
                          </span>
                          <span class="d-flex align-items-center justify-content-between flex-grow-1">
                              <span class="mr-1">
                                  <span class="h5 d-block fw-bolder">Administration publique</span>
                                  <span>Start learning today</span>
                              </span>
                              <span class="custom-control custom-radio">
                                <input class="custom-control-input" id="createAppOnlineLearning" type="radio" name="categoryRadio" />
                                <label class="custom-control-label" for="createAppOnlineLearning"></label>
                              </span>
                          </span>
                      </label>
                  </li>
              </ul>          
               
                <div class="d-flex justify-content-between mt-3">
                  <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
              <div id="personal-info-vertical" class="content">
                <div class="content-header">
                  <h5 class="mb-0">Personal Info</h5>
                  <small>Enter Your Personal Info.</small>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-first-name">First Name</label>
                    <input type="text" id="vertical-first-name" class="form-control" placeholder="John" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-last-name">Last Name</label>
                    <input type="text" id="vertical-last-name" class="form-control" placeholder="Doe" />
                  </div>
                </div>
                <div class="row">
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
              <div id="address2-step-vertical" class="content">
                <div class="content-header">
                  <h5 class="mb-0">Address</h5>
                  <small>Enter Your Address.</small>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-address">Address</label>
                    <input
                      type="text"
                      id="vertical-address"
                      class="form-control"
                      placeholder="98  Borough bridge Road, Birmingham"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-landmark">Landmark</label>
                    <input type="text" id="vertical-landmark" class="form-control" placeholder="Borough bridge" />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="pincode2">Pincode</label>
                    <input type="text" id="pincode2" class="form-control" placeholder="658921" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="city2">City</label>
                    <input type="text" id="city2" class="form-control" placeholder="Birmingham" />
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
              <div id="address-step-vertical" class="content">
                <div class="content-header">
                  <h5 class="mb-0">Address</h5>
                  <small>Enter Your Address.</small>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-address">Address</label>
                    <input
                      type="text"
                      id="vertical-address"
                      class="form-control"
                      placeholder="98  Borough bridge Road, Birmingham"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="vertical-landmark">Landmark</label>
                    <input type="text" id="vertical-landmark" class="form-control" placeholder="Borough bridge" />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="pincode2">Pincode</label>
                    <input type="text" id="pincode2" class="form-control" placeholder="658921" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="city2">City</label>
                    <input type="text" id="city2" class="form-control" placeholder="Birmingham" />
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
              <div id="social-links-vertical" class="content text-center">
                <h3>Submit 🥳</h3>
                <p>Submit your app to kickstart your project.</p>
                <img src="assets/images/illustration/pricing-Illustration.svg" height="218" alt="illustration" />
  
                <div class="d-flex justify-content-between mt-3">
                  <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                  </button>
                  <button class="btn btn-success btn-submit" rippleEffect (click)="onSubmit()">
                    <span class="align-middle d-sm-inline-block d-none">Submit</span>
                    <i data-feather="check" class="align-middle ml-sm-25 ml-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </ng-template>
      
        <section>

            <div class="row match-height">
              <!-- Congratulations Card -->
              <div class="col-12 col-md-6 col-lg-7">
                <div class="card card-congratulations">
                  <div class="card-body text-center">
                    <img src="assets/images/elements/decore-left.png" class="congratulations-img-left" alt="card-img-left" />
                    <img src="assets/images/elements/decore-right.png" class="congratulations-img-right" alt="card-img-right" />
                    <div class="avatar avatar-xl bg-primary shadow">
                      <div class="avatar-content">
                        <i data-feather="award" class="font-large-1"></i>
                      </div>
                    </div>
                    <div class="text-center">
                      <h1 class="mb-1 text-white">Bienvenue sur notre site !</h1>
                      <p class="card-text m-auto w-75">
                        Nous sommes heureux de vous accueillir sur notre site et espérons que vous y trouverez ce que vous cherchez.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!--/ Congratulations Card -->

              <!-- Medal Card -->
              <div class="col-12 col-md-6 col-lg-5">
                <div class="card card-congratulation-medal">
                  <div class="card-body">
                    <h5>Congratulations 🎉 {{User?.nom}}!</h5>
                    <p class="card-text font-small-3" style="width: 70%;">Suivez vos performances en temps réel en accédant à vos statistiques et votre tableau de bord ici</p>
                    <h3 class="mb-75 mt-4">
                      <a href="javascript:void(0);">Suivez les nouveautés!</a>
                    </h3>
                    <!-- <button routerLink="/dashboard" type="button" class="btn btn-primary" rippleEffect>Tableau de bord</button> -->
                    <button routerLink="" type="button" class="btn btn-primary" rippleEffect>Suivi de mes paiements</button>
                    <img src="assets/images/illustration/badge.svg" class="congratulation-medal" alt="Medal Pic" />
                  </div>
                </div>
              </div>
              <!--/ Medal Card -->
            </div>

            
        </section>


        <section class="faq-contact mb-5">
                <div class="row mt-5 pt-75">
                  <div class="col-12 text-center">
                    <h2>Découverte Ventexy pour la gestion de mon entreprise</h2>
                    <p class="mb-3">
                      "Vous avez des questions concernant l'utilisation de Ventexy pour votre entreprise ? N'hésitez pas à échanger avec nos conseillers."
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <div class="card text-center faq-contact-card shadow-none py-1">
                      <div class="card-body">
                        <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                          <i data-feather="phone-call" class="font-medium-3"></i>
                        </div>
                        <h4>+ (216) 23 059 660</h4>
                        <span class="text-body">Nous sommes toujours heureux d'aider!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card text-center faq-contact-card shadow-none py-1">
                      <div class="card-body">
                        <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                          <i data-feather="mail" class="font-medium-3"></i>
                        </div>
                        <h4>contact@ventexy.com</h4>
                        <span class="text-body">Le meilleur moyen d'obtenir une réponse rapidement !</span>
                      </div>
                    </div>
                  </div>
                </div>              
        </section>


        <!-- <section class="mb-5">
          <div class="row">
              <div class="col-12">
                  <swiper class="swiper-paginations swiper-container" [config]="swiperPaginations">
                    <img class="img-fluid" src="assets/images/banner/banner-1.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-2.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-3.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-4.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-5.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-6.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-7.jpg" alt="banner">
                    <img class="img-fluid" src="assets/images/banner/banner-8.jpg" alt="banner">
                  </swiper>
              </div>
          </div>
        </section> -->

    </div>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">


    <ng-template #modalCompleteAccount let-modal>
      <div class="modal-header bg-transparent">
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-5 px-sm-4 mx-50" tabindex="0" ngbAutofocus>
        <h1 class="address-title text-center mb-1" id="addNewAddressTitle">Add New Address</h1>
        <p class="address-subtitle text-center mb-2 pb-75">Add address for billing address</p>
        
        <div id="stepper2" class="bs-stepper vertical vertical-wizard-example">
          <div class="bs-stepper-header">
            <div class="step" data-target="#account-details-vertical">
              <button type="button" class="step-trigger">
                <span class="bs-stepper-box">
                  <i data-feather="book" class="font-medium-3"></i>
                </span>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Company Info</span>
                  <span class="bs-stepper-subtitle">Setup Company Info</span>
                </span>
              </button>
            </div>
            <div class="step" data-target="#personal-info-vertical">
              <button type="button" class="step-trigger">
                <span class="bs-stepper-box">
                  <i data-feather="user" class="font-medium-3"></i>
                </span>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Personal Info</span>
                  <span class="bs-stepper-subtitle">Add Personal Info</span>
                </span>
              </button>
            </div>
            <div class="step" data-target="#address-step-vertical">
              <button type="button" class="step-trigger">
                <span class="bs-stepper-box">
                  <i data-feather="map-pin" class="font-medium-3"></i>
                </span>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Address</span>
                  <span class="bs-stepper-subtitle">Add Address</span>
                </span>
              </button>
            </div>
            <div class="step" data-target="#address2-step-vertical">
              <button type="button" class="step-trigger">
                <span class="bs-stepper-box">
                  <i data-feather="map-pin" class="font-medium-3"></i>
                </span>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Address</span>
                  <span class="bs-stepper-subtitle">Add Address</span>
                </span>
              </button>
            </div>
            <div class="step" data-target="#social-links-vertical">
              <button type="button" class="step-trigger">
                <span class="bs-stepper-box">
                  <i data-feather="check" class="font-medium-3"></i>
                </span>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Submit</span>
                  <span class="bs-stepper-subtitle">Finish your account</span>
                </span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <div id="account-details-vertical" class="content">
              <h5>Nom de la société</h5>
              <input class="form-control" type="text" placeholder="Ventexy Admin" />

              <h5 class="mt-2 pt-1">Category</h5> 
              <ul class="list-group list-group-flush">
                <li class="list-group-item border-0 px-0">
                    <label for="createAppCrm" class="d-flex cursor-pointer">
                        <span class="avatar avatar-tag bg-light-info mr-1">
                            <i data-feather="globe" class="font-medium-5"></i>
                        </span>
                        <span class="d-flex align-items-center justify-content-between flex-grow-1">
                            <span class="mr-1">
                                <span class="h5 d-block fw-bolder">Entreprise</span>
                                <span>Scales with Any Business</span>
                            </span>
                            <span class="custom-control custom-radio">
                                <input class="custom-control-input" checked id="createAppCrm" type="radio" name="categoryRadio" />
                                <label class="custom-control-label" for="createAppCrm"></label>
                            </span>
                        </span>
                    </label>
                </li>
                <li class="list-group-item border-0 px-0">
                    <label for="createAppEcommerce" class="d-flex cursor-pointer">
                        <span class="avatar avatar-tag bg-light-success mr-1">
                            <i data-feather="user" class="font-medium-5"></i>
                        </span>
                        <span class="d-flex align-items-center justify-content-between flex-grow-1">
                            <span class="mr-1">
                                <span class="h5 d-block fw-bolder">Particulier</span>
                                <span>Grow Your Business With App</span>
                            </span>
                            <span class="custom-control custom-radio">
                              <input class="custom-control-input" id="createAppEcommerce" type="radio" name="categoryRadio" />
                              <label class="custom-control-label" for="createAppEcommerce"></label>
                            </span>
                        </span>
                    </label>
                </li>
                <li class="list-group-item border-0 px-0">
                    <label for="createAppOnlineLearning" class="d-flex cursor-pointer">
                        <span class="avatar avatar-tag bg-light-danger mr-1">
                            <i data-feather="briefcase" class="font-medium-5"></i>
                        </span>
                        <span class="d-flex align-items-center justify-content-between flex-grow-1">
                            <span class="mr-1">
                                <span class="h5 d-block fw-bolder">Administration publique</span>
                                <span>Start learning today</span>
                            </span>
                            <span class="custom-control custom-radio">
                              <input class="custom-control-input" id="createAppOnlineLearning" type="radio" name="categoryRadio" />
                              <label class="custom-control-label" for="createAppOnlineLearning"></label>
                            </span>
                        </span>
                    </label>
                </li>
            </ul>          
             
              <div class="d-flex justify-content-between mt-3">
                <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </div>
            <div id="personal-info-vertical" class="content">
              <div class="content-header">
                <h5 class="mb-0">Personal Info</h5>
                <small>Enter Your Personal Info.</small>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-first-name">First Name</label>
                  <input type="text" id="vertical-first-name" class="form-control" placeholder="John" />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-last-name">Last Name</label>
                  <input type="text" id="vertical-last-name" class="form-control" placeholder="Doe" />
                </div>
              </div>
              <div class="row">
              </div>
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </div>
            <div id="address2-step-vertical" class="content">
              <div class="content-header">
                <h5 class="mb-0">Address</h5>
                <small>Enter Your Address.</small>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-address">Address</label>
                  <input
                    type="text"
                    id="vertical-address"
                    class="form-control"
                    placeholder="98  Borough bridge Road, Birmingham"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-landmark">Landmark</label>
                  <input type="text" id="vertical-landmark" class="form-control" placeholder="Borough bridge" />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="pincode2">Pincode</label>
                  <input type="text" id="pincode2" class="form-control" placeholder="658921" />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="city2">City</label>
                  <input type="text" id="city2" class="form-control" placeholder="Birmingham" />
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </div>
            <div id="address-step-vertical" class="content">
              <div class="content-header">
                <h5 class="mb-0">Address</h5>
                <small>Enter Your Address.</small>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-address">Address</label>
                  <input
                    type="text"
                    id="vertical-address"
                    class="form-control"
                    placeholder="98  Borough bridge Road, Birmingham"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="vertical-landmark">Landmark</label>
                  <input type="text" id="vertical-landmark" class="form-control" placeholder="Borough bridge" />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="pincode2">Pincode</label>
                  <input type="text" id="pincode2" class="form-control" placeholder="658921" />
                </div>
                <div class="form-group col-md-6">
                  <label class="form-label" for="city2">City</label>
                  <input type="text" id="city2" class="form-control" placeholder="Birmingham" />
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </div>
            <div id="social-links-vertical" class="content text-center">
              <h3>Submit 🥳</h3>
              <p>Submit your app to kickstart your project.</p>
              <img src="assets/images/illustration/pricing-Illustration.svg" height="218" alt="illustration" />

              <div class="d-flex justify-content-between mt-3">
                <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                </button>
                <button class="btn btn-success btn-submit" rippleEffect (click)="onSubmit()">
                  <span class="align-middle d-sm-inline-block d-none">Submit</span>
                  <i data-feather="check" class="align-middle ml-sm-25 ml-0"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
    


    <div class="row match-height">
      <!-- Medal Card -->
      <div class="col-xl-4 col-md-6 col-12">
        <div class="card card-congratulation-medal">
          <div class="card-body">
            <h5>{{ 'SAMPLE.CONGRATULATIONS' | translate }} 🎉 {{ currentUser?.firstName }}!</h5>
            <p class="card-text font-small-3">{{ 'SAMPLE.BADGE' | translate }}</p>
            <h3 class="mb-75 mt-2 pt-50">
              <a href="javascript:void(0);">$48.9k</a>
            </h3>
            
            <button type="button" class="btn btn-primary" rippleEffect>View Sales</button>
            <img src="assets/images/illustration/badge.svg" class="congratulation-medal" alt="Medal Pic" />
          </div>
        </div>
      </div>
      <!--/ Medal Card -->

      <!-- Statistics Card -->
      <div class="col-xl-8 col-md-6 col-12">
        <div class="card card-statistics">
          <div class="card-header">
            <h4 class="card-title">Statistics</h4>
            <div class="d-flex align-items-center">
              <p class="card-text font-small-2 mr-25 mb-0">Updated 1 month ago</p>
            </div>
          </div>
          <div class="card-body statistics-body">
            <div class="row">
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="avatar bg-light-primary mr-2">
                    <div class="avatar-content">
                      <i data-feather="trending-up" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ data.statistics.analyticsData.sales }}</h4>
                    <p class="card-text font-small-3 mb-0">Sales</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="avatar bg-light-info mr-2">
                    <div class="avatar-content">
                      <i data-feather="user" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ data.statistics.analyticsData.customers }}</h4>
                    <p class="card-text font-small-3 mb-0">Customers</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
                <div class="media">
                  <div class="avatar bg-light-danger mr-2">
                    <div class="avatar-content">
                      <i data-feather="box" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ data.statistics.analyticsData.products }}</h4>
                    <p class="card-text font-small-3 mb-0">Products</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="media">
                  <div class="avatar bg-light-success mr-2">
                    <div class="avatar-content">
                      <i data-feather="dollar-sign" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ data.statistics.analyticsData.revenue }}</h4>
                    <p class="card-text font-small-3 mb-0">Revenue</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ Statistics Card -->
    </div>

    <div class="row match-height">
      <div class="col-lg-4 col-12">
        <div class="row match-height">
          <!-- Bar Chart Orders -->
          <div class="col-lg-6 col-md-3 col-6">
            <div class="card">
              <div class="card-body pb-50">
                <h6>Orders</h6>
                <h2 class="font-weight-bolder mb-1">{{ data.ordersChart.analyticsData.orders }}</h2>
                <div id="statistics-bar-chart" #statisticsBarChartRef>
                  <!-- Apexchart component-->
                  <apx-chart
                    [series]="data.ordersChart.series"
                    [chart]="{
                      height: 70,
                      width: isMenuToggled === false ? statisticsBarChartRef.offsetWidth : statisticsBar.chart.width,
                      type: 'bar',
                      stacked: true,
                      toolbar: {
                        show: false
                      }
                    }"
                    [tooltip]="statisticsBar.tooltip"
                    [colors]="statisticsBar.colors"
                    [dataLabels]="statisticsBar.dataLabels"
                    [grid]="statisticsBar.grid"
                    [plotOptions]="statisticsBar.plotOptions"
                    [dataLabels]="statisticsBar.dataLabels"
                    [xaxis]="statisticsBar.xaxis"
                    [yaxis]="statisticsBar.yaxis"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
          <!--/ Bar Chart Orders -->

          <!-- Line Chart Profit -->
          <div class="col-lg-6 col-md-3 col-6">
            <div class="card card-tiny-line-stats">
              <div class="card-body pb-50">
                <h6>Profit</h6>
                <h2 class="font-weight-bolder mb-1">{{ data.profitChart.analyticsData.profit }}</h2>
                <div id="statistics-line-chart" #statisticsLineChartRef>
                  <apx-chart
                    [chart]="{
                      height: 70,
                      width: isMenuToggled === false ? statisticsLineChartRef.offsetWidth : statisticsLine.chart.width,
                      type: 'line',
                      toolbar: {
                        show: false
                      },
                      zoom: {
                        enabled: false
                      }
                    }"
                    [grid]="statisticsLine.grid"
                    [stroke]="statisticsLine.stroke"
                    [colors]="statisticsLine.colors"
                    [series]="data.profitChart.series"
                    [markers]="statisticsLine.markers"
                    [xaxis]="statisticsLine.xaxis"
                    [yaxis]="statisticsLine.yaxis"
                    [tooltip]="statisticsLine.tooltip"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
          <!--/ Line Chart Profit -->

          <!-- Earnings Chart -->
          <div class="col-lg-12 col-md-6 col-12">
            <div class="card earnings-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <h4 class="card-title mb-1">Earnings</h4>
                    <div class="font-small-2">This Month</div>
                    <h5 class="mb-1">$4055.56</h5>
                    <p class="card-text text-muted font-small-2">
                      <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
                    </p>
                  </div>
                  <div class="col-6">
                    <div id="earnings-donut-chart" #earningChartRef>
                      <!-- Apexchart component-->
                      <apx-chart
                        [chart]="{
                          type: 'donut',
                          width:
                            isMenuToggled === false ? earningChartRef.offsetWidth : earningChartoptions.chart.width,
                          height: 120,
                          toolbar: {
                            show: false
                          }
                        }"
                        [dataLabels]="earningChartoptions.dataLabels"
                        [series]="earningChartoptions.series"
                        [legend]="earningChartoptions.legend"
                        [labels]="earningChartoptions.labels"
                        [stroke]="earningChartoptions.stroke"
                        [colors]="earningChartoptions.colors"
                        [grid]="earningChartoptions.grid"
                        [plotOptions]="earningChartoptions.plotOptions"
                      ></apx-chart>
                      <!-- / Apexchart component-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/ Earnings Chart -->
        </div>
      </div>
      <div class="col-lg-8 col-12">
        <!-- Revenue Report Card -->
        <div class="card card-revenue-budget">
          <div class="row mx-0">
            <div class="col-md-8 col-12 revenue-report-wrapper">
              <div class="d-sm-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title mb-50 mb-sm-0">Revenue Report</h4>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-2">
                    <span class="bullet bullet-primary font-small-3 mr-50 cursor-pointer"></span>
                    <span>Earning</span>
                  </div>
                  <div class="d-flex align-items-center ml-75">
                    <span class="bullet bullet-warning font-small-3 mr-50 cursor-pointer"></span>
                    <span>Expense</span>
                  </div>
                </div>
              </div>
              <div id="revenue-report-chart" #revenueReportChartRef>
                <apx-chart
                  [series]="data.revenueReport.earningExpenseChart.series"
                  [chart]="{
                    height: 230,
                    width:
                      isMenuToggled === false
                        ? revenueReportChartRef.offsetWidth
                        : revenueReportChartoptions.chart.width,
                    stacked: true,
                    type: 'bar',
                    toolbar: { show: false }
                  }"
                  [plotOptions]="revenueReportChartoptions.plotOptions"
                  [colors]="revenueReportChartoptions.colors"
                  [dataLabels]="revenueReportChartoptions.dataLabels"
                  [legend]="revenueReportChartoptions.legend"
                  [grid]="revenueReportChartoptions.grid"
                  [xaxis]="revenueReportChartoptions.xaxis"
                  [yaxis]="revenueReportChartoptions.yaxis"
                ></apx-chart>
              </div>
            </div>
            <div class="col-md-4 col-12 budget-wrapper">
              <div class="btn-group">
                <div ngbDropdown>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    ngbDropdownToggle
                    rippleEffect
                  >
                    2020
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:void(0);">2020</a>
                    <a ngbDropdownItem href="javascript:void(0);">2019</a>
                    <a ngbDropdownItem href="javascript:void(0);">2018</a>
                  </div>
                </div>
              </div>
              <h2 class="mb-25">{{ data.revenueReport.analyticsData.currentBudget }}</h2>
              <div class="d-flex justify-content-center">
                <span class="font-weight-bolder mr-25">Budget:</span>
                <span>{{ data.revenueReport.analyticsData.totalBudget }}</span>
              </div>
              <div id="budget-chart" #budgetChartRef>
                <apx-chart
                  [series]="data.revenueReport.budgetChart.series"
                  [chart]="{
                    height: 80,
                    width: isMenuToggled === false ? budgetChartRef.offsetWidth : budgetChartoptions.chart.width,
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    sparkline: { enabled: true }
                  }"
                  [stroke]="budgetChartoptions.stroke"
                  [colors]="budgetChartoptions.colors"
                  [tooltip]="budgetChartoptions.tooltip"
                ></apx-chart>
              </div>
              <button type="button" class="btn btn-primary" rippleEffect>Increase Budget</button>
            </div>
          </div>
        </div>
        <!--/ Revenue Report Card -->
      </div>
    </div>

    <div class="row match-height">
      <!-- Company Table -->
      <div class="col-lg-8 col-12">
        <div class="card card-company-table">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Category</th>
                    <th>Views</th>
                    <th>Revenue</th>
                    <th>Sales</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/toolbox.svg" alt="Toolbar svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Dixons</div>
                          <div class="font-small-2 text-muted">meguc@ruj.io</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-primary mr-1">
                          <div class="avatar-content">
                            <i data-feather="monitor" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Technology</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">23.4k</span>
                        <span class="font-small-2 text-muted">in 24 hours</span>
                      </div>
                    </td>
                    <td>$891.2</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">68%</span>
                        <i data-feather="trending-down" class="text-danger font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/parachute.svg" alt="Parachute svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Motels</div>
                          <div class="font-small-2 text-muted">vecav@hodzi.co.uk</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-success mr-1">
                          <div class="avatar-content">
                            <i data-feather="coffee" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Grocery</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">78k</span>
                        <span class="font-small-2 text-muted">in 2 days</span>
                      </div>
                    </td>
                    <td>$668.51</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">97%</span>
                        <i data-feather="trending-up" class="text-success font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/brush.svg" alt="Brush svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Zipcar</div>
                          <div class="font-small-2 text-muted">davcilse@is.gov</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-warning mr-1">
                          <div class="avatar-content">
                            <i data-feather="watch" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Fashion</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">162</span>
                        <span class="font-small-2 text-muted">in 5 days</span>
                      </div>
                    </td>
                    <td>$522.29</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">62%</span>
                        <i data-feather="trending-up" class="text-success font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/star.svg" alt="Star svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Owning</div>
                          <div class="font-small-2 text-muted">us@cuhil.gov</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-primary mr-1">
                          <div class="avatar-content">
                            <i data-feather="monitor" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Technology</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">214</span>
                        <span class="font-small-2 text-muted">in 24 hours</span>
                      </div>
                    </td>
                    <td>$291.01</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">88%</span>
                        <i data-feather="trending-up" class="text-success font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/book.svg" alt="Book svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Cafés</div>
                          <div class="font-small-2 text-muted">pudais@jife.com</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-success mr-1">
                          <div class="avatar-content">
                            <i data-feather="coffee" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Grocery</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">208</span>
                        <span class="font-small-2 text-muted">in 1 week</span>
                      </div>
                    </td>
                    <td>$783.93</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">16%</span>
                        <i data-feather="trending-down" class="text-danger font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/rocket.svg" alt="Rocket svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Kmart</div>
                          <div class="font-small-2 text-muted">bipri@cawiw.com</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-warning mr-1">
                          <div class="avatar-content">
                            <i data-feather="watch" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Fashion</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">990</span>
                        <span class="font-small-2 text-muted">in 1 month</span>
                      </div>
                    </td>
                    <td>$780.05</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">78%</span>
                        <i data-feather="trending-up" class="text-success font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar rounded">
                          <div class="avatar-content">
                            <img src="assets/images/icons/speaker.svg" alt="Speaker svg" />
                          </div>
                        </div>
                        <div>
                          <div class="font-weight-bolder">Payers</div>
                          <div class="font-small-2 text-muted">luk@izug.io</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="avatar bg-light-warning mr-1">
                          <div class="avatar-content">
                            <i data-feather="watch" class="font-medium-3"></i>
                          </div>
                        </div>
                        <span>Fashion</span>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">12.9k</span>
                        <span class="font-small-2 text-muted">in 12 hours</span>
                      </div>
                    </td>
                    <td>$531.49</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bolder mr-1">42%</span>
                        <i data-feather="trending-up" class="text-success font-medium-1"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--/ Company Table -->

      <!-- Developer Meetup Card -->
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card card-developer-meetup">
          <div class="bg-light-primary rounded-top text-center">
            <img src="assets/images/illustration/email.svg" alt="Meeting Pic" height="170" />
          </div>
          <div class="card-body">
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">THU</h6>
                <h3 class="mb-0">24</h3>
              </div>
              <div class="my-auto">
                <h4 class="card-title mb-25">Developer Meetup</h4>
                <p class="card-text mb-0">Meet world popular developers</p>
              </div>
            </div>
            <div class="media">
              <div class="avatar bg-light-primary rounded mr-1">
                <div class="avatar-content">
                  <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                </div>
              </div>
              <div class="media-body">
                <h6 class="mb-0">Sat, May 25, 2020</h6>
                <small>10:AM to 6:PM</small>
              </div>
            </div>
            <div class="media">
              <div class="avatar bg-light-primary rounded mr-1">
                <div class="avatar-content">
                  <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                </div>
              </div>
              <div class="media-body">
                <h6 class="mb-0">Central Park</h6>
                <small>Manhattan, New york City</small>
              </div>
            </div>
            <div class="avatar-group">
              <div placement="bottom" ngbTooltip="Billy Hopkins" class="avatar pull-up">
                <img src="assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" width="33" height="33" />
              </div>
              <div placement="bottom" ngbTooltip="Amy Carson" class="avatar pull-up">
                <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" width="33" height="33" />
              </div>
              <div placement="bottom" ngbTooltip="Brandon Miles" class="avatar pull-up">
                <img src="assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" width="33" height="33" />
              </div>
              <div placement="bottom" ngbTooltip="Daisy Weber" class="avatar pull-up">
                <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="33" height="33" />
              </div>
              <div placement="bottom" ngbTooltip="Jenny Looper" class="avatar pull-up">
                <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" width="33" height="33" />
              </div>
              <h6 class="align-self-center cursor-pointer ml-50 mb-0">+42</h6>
            </div>
          </div>
        </div>
      </div>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card card-browser-states">
          <div class="card-header">
            <div class="broweser-states-header">
              <div class="broweser-states-heade">
                <h4 class="card-title">Browser States</h4>
                <p class="card-text font-small-2">Counter August 2020</p>
              </div>
            </div>

            <div ngbDropdown>
              <a
                ngbDropdownToggle
                href="javascript:void(0);"
                class="hide-arrow"
                id="dropdownBrowserState"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
              </a>
              <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                <a href="javascript:void(0)" ngbDropdownItem>Last 28 Days</a>
                <a href="javascript:void(0)" ngbDropdownItem>Last Month</a>
                <a href="javascript:void(0)" ngbDropdownItem>Last Year</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="browser-states">
              <div class="media">
                <img src="assets/images/icons/google-chrome.png" class="rounded mr-1" height="30" alt="Google Chrome" />
                <h6 class="align-self-center mb-0">Google Chrome</h6>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-body-heading mr-1">54.4%</div>
                <div class="state-chart-primary" #statePrimaryChartRef>
                  <apx-chart
                    [chart]="statePrimaryChartoptions.chart"
                    [grid]="statePrimaryChartoptions.grid"
                    [colors]="statePrimaryChartoptions.colors"
                    [series]="statePrimaryChartoptions.series"
                    [plotOptions]="statePrimaryChartoptions.plotOptions"
                    [stroke]="statePrimaryChartoptions.stroke"
                  ></apx-chart>
                </div>
              </div>
            </div>
            <div class="browser-states">
              <div class="media">
                <img
                  src="assets/images/icons/mozila-firefox.png"
                  class="rounded mr-1"
                  height="30"
                  alt="Mozila Firefox"
                />
                <h6 class="align-self-center mb-0">Mozila Firefox</h6>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-body-heading mr-1">6.1%</div>
                <div class="state-chart-warning" #stateWarningChartRef>
                  <apx-chart
                    [chart]="stateWarningChartoptions.chart"
                    [grid]="stateWarningChartoptions.grid"
                    [colors]="stateWarningChartoptions.colors"
                    [series]="stateWarningChartoptions.series"
                    [plotOptions]="stateWarningChartoptions.plotOptions"
                    [stroke]="stateWarningChartoptions.stroke"
                  ></apx-chart>
                </div>
              </div>
            </div>
            <div class="browser-states">
              <div class="media">
                <img src="assets/images/icons/apple-safari.png" class="rounded mr-1" height="30" alt="Apple Safari" />
                <h6 class="align-self-center mb-0">Apple Safari</h6>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-body-heading mr-1">14.6%</div>
                <div class="state-chart-secondary" #stateSecondaryChartRef>
                  <apx-chart
                    [chart]="stateSecondaryChartoptions.chart"
                    [grid]="stateSecondaryChartoptions.grid"
                    [colors]="stateSecondaryChartoptions.colors"
                    [series]="stateSecondaryChartoptions.series"
                    [plotOptions]="stateSecondaryChartoptions.plotOptions"
                    [stroke]="stateSecondaryChartoptions.stroke"
                  ></apx-chart>
                </div>
              </div>
            </div>
            <div class="browser-states">
              <div class="media">
                <img
                  src="assets/images/icons/internet-explorer.png"
                  class="rounded mr-1"
                  height="30"
                  alt="Internet Explorer"
                />
                <h6 class="align-self-center mb-0">Internet Explorer</h6>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-body-heading mr-1">4.2%</div>
                <div class="state-chart-info" #stateInfoChartRef>
                  <apx-chart
                    [chart]="stateInfoChartoptions.chart"
                    [grid]="stateInfoChartoptions.grid"
                    [colors]="stateInfoChartoptions.colors"
                    [series]="stateInfoChartoptions.series"
                    [plotOptions]="stateInfoChartoptions.plotOptions"
                    [stroke]="stateInfoChartoptions.stroke"
                  ></apx-chart>
                </div>
              </div>
            </div>
            <div class="browser-states">
              <div class="media">
                <img src="assets/images/icons/opera.png" class="rounded mr-1" height="30" alt="Opera Mini" />
                <h6 class="align-self-center mb-0">Opera Mini</h6>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-body-heading mr-1">8.4%</div>
                <div class="state-chart-danger" #stateDangerChartRef>
                  <apx-chart
                    [chart]="stateDangerChartoptions.chart"
                    [grid]="stateDangerChartoptions.grid"
                    [colors]="stateDangerChartoptions.colors"
                    [series]="stateDangerChartoptions.series"
                    [plotOptions]="stateDangerChartoptions.plotOptions"
                    [stroke]="stateDangerChartoptions.stroke"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Browser States Card -->

      <!-- Goal Overview Card -->
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h4 class="card-title">Goal Overview</h4>
            <i data-feather="help-circle" class="font-medium-3 text-muted cursor-pointer"></i>
          </div>
          <div class="card-body p-0">
            <div id="goalChartoptions" class="mb-1 mt-1 pt-1" #goalChartRef>
              <!-- Apexchart component-->
              <apx-chart
                [chart]="{
                  height: 245,
                  width: isMenuToggled === false ? goalChartRef.offsetWidth : goalChartoptions.chart.width,
                  type: 'radialBar',
                  sparkline: {
                    enabled: true
                  },
                  dropShadow: {
                    enabled: true,
                    blur: 3,
                    left: 1,
                    top: 1,
                    opacity: 0.1
                  }
                }"
                [colors]="goalChartoptions.colors"
                [plotOptions]="goalChartoptions.plotOptions"
                [fill]="goalChartoptions.fill"
                [series]="data.goalOverview.series"
                [stroke]="goalChartoptions.stroke"
                [grid]="goalChartoptions.grid"
              ></apx-chart>
              <!-- / Apexchart component-->
            </div>
            <div class="row border-top text-center mx-0">
              <div class="col-6 border-right py-1">
                <p class="card-text text-muted mb-0">Completed</p>
                <h3 class="font-weight-bolder mb-0">{{ data.goalOverview.analyticsData.completed }}</h3>
              </div>
              <div class="col-6 py-1">
                <p class="card-text text-muted mb-0">In Progress</p>
                <h3 class="font-weight-bolder mb-0">{{ data.goalOverview.analyticsData.inProgress }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ Goal Overview Card -->

      <!-- Transactions Card -->
      <div class="col-lg-4 col-md-6 col-12">
        <div class="card card-transaction">
          <div class="card-header">
            <h4 class="card-title">Transactions</h4>
            <!-- <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i> -->
            <div ngbDropdown>
              <a
                ngbDropdownToggle
                href="javascript:void(0);"
                class="hide-arrow"
                id="dropdownTransactions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
              </a>
              <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownTransactions">
                <a href="javascript:void(0)" ngbDropdownItem>Last 28 Days</a>
                <a href="javascript:void(0)" ngbDropdownItem>Last Month</a>
                <a href="javascript:void(0)" ngbDropdownItem>Last Year</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="transaction-item">
              <div class="media">
                <div class="avatar bg-light-primary rounded">
                  <div class="avatar-content">
                    <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title">Wallet</h6>
                  <small>Starbucks</small>
                </div>
              </div>
              <div class="font-weight-bolder text-danger">- $74</div>
            </div>
            <div class="transaction-item">
              <div class="media">
                <div class="avatar bg-light-success rounded">
                  <div class="avatar-content">
                    <i data-feather="check" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title">Bank Transfer</h6>
                  <small>Add Money</small>
                </div>
              </div>
              <div class="font-weight-bolder text-success">+ $480</div>
            </div>
            <div class="transaction-item">
              <div class="media">
                <div class="avatar bg-light-danger rounded">
                  <div class="avatar-content">
                    <i data-feather="dollar-sign" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title">Paypal</h6>
                  <small>Add Money</small>
                </div>
              </div>
              <div class="font-weight-bolder text-success">+ $590</div>
            </div>
            <div class="transaction-item">
              <div class="media">
                <div class="avatar bg-light-warning rounded">
                  <div class="avatar-content">
                    <i data-feather="credit-card" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title">Mastercard</h6>
                  <small>Ordered Food</small>
                </div>
              </div>
              <div class="font-weight-bolder text-danger">- $23</div>
            </div>
            <div class="transaction-item">
              <div class="media">
                <div class="avatar bg-light-info rounded">
                  <div class="avatar-content">
                    <i data-feather="trending-up" class="avatar-icon font-medium-3"></i>
                  </div>
                </div>
                <div class="media-body">
                  <h6 class="transaction-title">Transfer</h6>
                  <small>Refund</small>
                </div>
              </div>
              <div class="font-weight-bolder text-success">+ $98</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Transactions Card -->
    </div>
  </div>
</div>

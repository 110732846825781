import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subject } from 'rxjs';
import { takeUntil,first } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { async } from '@angular/core/testing';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { User } from 'app/auth/models/user';
import { MyPermissionsService } from 'app/auth/service/my-permissions.service';
import Stepper from 'bs-stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy  {


  public swiperPaginations: SwiperConfigInterface = {
    pagination: true,
  };

   

  private _unsubscribeAll: Subject<any>;

  dataCompany: any;
  currency = 'EUR';
  menu: any;

  cards_home = []
  User: any;
  loading = true
  permissions: any;

  public currentUser: User;
  public isAdmin: boolean;



  private verticalWizardStepper: Stepper;
  @ViewChild('modalCompleteAccount', { static: false }) modalCompleteAccount: ElementRef;
  
  constructor(
    private _authenticationService: AuthenticationService,
    private _coreMenuService: CoreMenuService,
    private shepherdService: ShepherdService,
    private _permissionsService:MyPermissionsService,
    private modalService: NgbModal
  ) {
    this._unsubscribeAll = new Subject();
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.isAdmin = this._authenticationService.isAdmin;
  }

  ngOnInit(): void {
    this.User = JSON.parse(localStorage.getItem('ventexy-6565235000-5656-175-55656-admin'));


  }


  modalOpenLG(modalLG) {
    this.modalService.open(modalLG, {
      size: 'lg',
      centered:true
    });
  }


  verticalWizardNext() {
    this.verticalWizardStepper.next();
  }
  /**
   * Vertical Wizard Stepper Previous
   */
  verticalWizardPrevious() {
    this.verticalWizardStepper.previous();
  }
  onSubmit() {
    alert('Submitted!!');
    return false;
  }




  startTour() {
    this.shepherdService.start();
  }


  ngAfterViewInit() {
    // tour steps
    this.shepherdService.defaultStepOptions = {
      cancelIcon: {
        enabled: true
      }
    };
    this.shepherdService.modal = true;

    this.shepherdService.addSteps([
      {
        title: 'thème',
        text: 'Personnaliser votre thème',
        attachTo: {
          element: '.customizer-toggle',
          on: 'left'
        },
        buttons: [
          {
            text: 'Fermer',
            type: 'cancel',
            classes: 'btn btn-sm btn-outline-primary btn-next'
          }
        ],
        useModalOverlay: true
      }
    ]);
  }
  

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MyPermissionsService implements Resolve<any> {
  public permissions: BehaviorSubject<any>;

  private _BackendUrl = environment._BackendUrl;
  

  constructor(private _httpClient: HttpClient) {
    this.permissions = new BehaviorSubject({});
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([]).then(() => {
        resolve();
      }, reject);
    });
  }

  // get_my_permissions(): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.get<any>(this._BackendUrl+"get_my_permissions").subscribe((response: any) => {
  //       let rows = []
  //         if(response.msg == "user"){
  //            rows = response.results[0].role.permissions;
  //         }
  //         this.permissions.next(rows);
  //         resolve(rows);
  //     }, reject);
  //   });
  // }

 
}

export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      DASHBOARD: {
        STATITSIQUES : 'Statistiques',
        VENTE : 'Ventes',
        DEVIS : 'Devis',
        COMMANDECLIENT : 'Commande client',
        BONLIVRAISON : 'Bon de livraison',
        BONSORTIE : 'Bon de sortie',
        FACTURECLIENT : 'Facture',
        TURBOFACTURE : 'Facture Turbo',
        AVOIRCLIENT : "Facture d'avoir",
        ACHATS : 'Achats',
        DEPENSES : 'Dépenses',
        DEPENSESENATTENTE : 'Dépenses en attente',
        BONCOMMANDE : 'Bon de commande',
        BONRECEPTION : 'Bon de réception',
        FACTUREFOURNISSEUR : 'Facture',
        PRODUITS : 'Produits',
        GROUPESPRODUITS : 'Groupe de produits',
        CATEGORIES : "Catégories",
        CLIENTS : "Clients",
        LISTECLIENTS : "Clients",
        FOURNISSEURS : "Fournisseurs",
        CONFIGURATION : "Configuration",
        COLLAPSIBLE: 'Tableau de bord',
        ACCUEIL : 'Accueil',
        USERS : 'Utilisateurs',
        ROLES : 'Roles',
        PERMISSIONS : 'Permissions',
        MODULES : 'MODULES',
        ENTREPRISE : 'Entreprises',
        MESENTREPRISE : 'Mes Entreprises',
        NOUVELLEENTREPRISE : 'Nouvelle Entreprise',
        NEW:'New',
        STOCK : 'Stock',
        GESTIONSTOCK : 'Gestion De Stock',
        INVENTAIRE : 'Inventaire',
        REGLEMENTS : 'Réglements',
        PAIEMENTS : 'Paiements',
        RETARDPAIEMENTS : 'Retard de paiement',
        RELANCES : 'Relances',
        COMINGDUE : 'Venant à échéance',
        RELANCEAUTO : 'Relance auto',
        RELANCESLETTRES : 'Lettres de relance',
        ACOMPTES : 'Acomptes',
        RECURRENCES : 'Récurrence',
        GROUPBL : 'Regroupement BL',
        GROUPBR : 'Regroupement BR',
        CHEQUES : 'Gestion des chèques',
        MOVEMENTSSTOCK : 'Mouvements',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce',
        VEHICULES : 'Véhicules',
        LISTEVEHICULES : 'Liste des Véhicules',
        LISTECONDUCTEURS : 'Conducteurs',
        CRM : 'CRM',
        PIPELINES : 'Pipelines',
        TASKS : 'Activités',
        EMAIL : 'Email',
        CALENDRIER : 'Calendrier',
        GESTIONCHEQUES : 'Chèques',
        PROSPECTS : 'Prospects',
        CONTACTS : 'Contacts',
        FACTURATION : 'Facturation',
        CHEQUESRECUS : 'Chèques reçus',
        CHEQUESEMIS : 'Chèques émis',
        LOTS : 'Lots',
        NIVEAUSTOCK : 'Niveau de stock',
        ENTREESSTOCK : 'Entrées de stock',
        SORTIESSTOCK : 'Sorties de stock',
        REGISTRESTOCK : 'Journal de stock',


        LE : 'Liste Entreprises',
        VAPP : 'Visiteurs App',
        VSITE : 'Visiteurs SiteWeb',
      },
    }
  }
}

<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <app-navbar-bookmark></app-navbar-bookmark>



  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <!-- <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a> -->

        <a ngbDropdownItem>
          <i class="flag-icon flag-icon-fr"></i> {{ 'French' }}
        </a>

      </div>
    </li>
    <!--/ Language selection -->


    <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>



    <app-navbar-search></app-navbar-search>

    <!-- <app-navbar-activities></app-navbar-activities> -->

    <app-navbar-notification></app-navbar-notification>


    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{User?.nom}}</span><span class="user-status">{{User?.role =="Super Admin" ? "Propriétaire" : User?.role }}</span>
        </div>
        <span *ngIf="User?.avatar?.length > 0; else customAvatar" class="avatar"
          ><img
            class="round"
            src="{{ _BackendUrl +'img/users/'+ User.avatar }}"
            alt="avatar"
            height="40"
            width="40" /><span class="avatar-status-online"></span
        ></span>

        <ng-template #customAvatar>
          <div
            class="avatar"
            [ngClass]="{
              'bg-light-primary': ['a','b','c','d','e','0','5'].includes((User?.nom[0]).toLowerCase()),
              'bg-light-success': ['f','g','h','i','j','1','6'].includes((User?.nom[0]).toLowerCase()),
              'bg-light-danger': ['k','l','m','n','o','2','7'].includes((User?.nom[0]).toLowerCase()),
              'bg-light-warning': ['p','q','r','s','t','3','8'].includes((User?.nom[0]).toLowerCase()),
              'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((User?.nom[0]).toLowerCase())
            }"
          >
            <div class="avatar-content">{{  (User?.nom |uppercase) | initials }}</div>
          </div>
        </ng-template>

      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/account/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profil</a
        >
        <!-- <a ngbDropdownItem [routerLink]="['/account/companies']"><span [data-feather]="'globe'" [class]="'mr-50'"></span> Entreprises</a
        > -->
        <a ngbDropdownItem [routerLink]="['/account/settings']"><span [data-feather]="'tool'" [class]="'mr-50'"></span> Paramètres</a>
        <a ngbDropdownItem [routerLink]="['/configuration']"><span [data-feather]="'settings'" [class]="'mr-50'"></span> Configuration</a>
        <a ngbDropdownItem [routerLink]="['/account/subscription']"><span [data-feather]="'shopping-bag'" [class]="'mr-50'"></span> Abonnement</a>
        <div class="dropdown-divider"></div>

        <a ngbDropdownItem [routerLink]="['/account/pricing']"><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> 
          Tarification</a>
        <!-- <a ngbDropdownItem [routerLink]="['/account/faq']"><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a> -->
          
        <a ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span> Déconnexion  </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

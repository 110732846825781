import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Public
  public search = '';
  public apiData = [];
  // public onApiDataChange: BehaviorSubject<any>;
  public onIsBookmarkOpenChange: BehaviorSubject<any>;

  private _BackendUrl = environment._BackendUrl;

  
  constructor(private _httpClient: HttpClient) {
    // this.onApiDataChange = new BehaviorSubject('');
    this.onIsBookmarkOpenChange = new BehaviorSubject(false);
    // this.getSearchData();
  }

  
  searchData(data): Observable<any> {
    return this._httpClient.post<any>(this._BackendUrl+"searchData",data);
  }


  // getSearchData(): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.get('api/search-data').subscribe((response: any) => {
  //       this.apiData = response;
  //       this.onApiDataChange.next(this.apiData);
  //       resolve(this.apiData);
  //     }, reject);
  //   });
  // }
}

<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Login v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
          <h2 class="brand-text text-primary ml-1">Ventexy</h2>
        </a>

        <h4 class="card-title mb-1">Bienvenue à  Ventexy Espace admin!</h4>
        <p class="card-text mb-2">Commencez votre aventure en vous connectant à votre compte</p>

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>


        <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="login-email" class="form-label">Email</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              placeholder="john@example.com"
              aria-describedby="login-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Ce champs est obligatoire</div>
              <div *ngIf="f.email.errors.email">L'e-mail doit être une adresse e-mail valide</div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Mot de passe</label>
              <a routerLink="/pages/authentication/forgot-password-v1">
                <small>Mot de passe oublié ?</small>
              </a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                tabindex="2"
                placeholder="·········"
                aria-describedby="login-password"
                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
              />

              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Ce champs est obligatoire</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="remember-me" tabindex="3" />
              <label class="custom-control-label" for="remember-me"> Se souvenir de moi </label>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
          </button>
        </form>
<!-- 
        <p class="text-center mt-2">
          <span>Nouveau sur notre plateforme ?</span>
          <a routerLink="/sign-up">
            <span>&nbsp;Créer un compte</span>
          </a>
        </p>

        <div class="divider my-2">
          <div class="divider-text">ou</div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <a class="btn btn-connect btn-block mt-0">
            <img src="assets/images/icons/google.png" class="mr-50" alt=""> Google
          </a>
          <a  class="btn btn-connect btn-block mt-0">
            <img src="assets/images/icons/facebook.png" class="mr-50" alt=""> Facebook
          </a>
        </div> -->
      </div>
    </div>
    <!-- /Login v1 -->
  </div>
</div>

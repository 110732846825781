<li ngbDropdown class="nav-item dropdown-notification mr-25">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown" placement="bottom" ngbTooltip="Historique d'activités"
      ><i class="ficon" data-feather="activity"></i></a>
    <ul
      ngbDropdownMenu
      aria-labelledby="navbarNotificationDropdown"
      class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    >
      <!-- Notifications header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Historique d'activités</h4>
          <div style="width: 135px;">
            <select class="form-control form-control-sm">
                <option selected>All users</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three users baka</option>
              </select>
          </div>
        </div>
      </li>
      <!--/ Notifications header -->
  
      <!-- Notifications content -->
      <li class="scrollable-container media-list" [perfectScrollbar]>
        <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications.messages">
          <div class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar">
                <img [src]="message.image" alt="avatar" width="32" height="32" />
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading" [innerHTML]="message.heading"></p>
              <small class="notification-text">{{ message.text }} {{ message.date }}</small>
            </div>
          </div></a
        >
      </li>
      <!--/ Notifications content -->
  
    </ul>
  </li>
  
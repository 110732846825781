<div class="content-wrapper container-xxl p-0">
    <div class="content-body">


        <ng-container>
            <div class="content-header row">
              <div class="content-header-left col-md-6 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12 d-flex">
                    <h2 class="content-header-title float-left mb-0">
                      Clients
                    </h2>

                    <!-- app-breadcrumb start -->
                    <div class="breadcrumb-wrapper d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a routerLink="/home">Accueil</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a routerLink="/list-companies">Entreprises</a>
                            </li>
                            <li class="breadcrumb-item">
                                <span>Détails client</span>
                            </li>
                        </ol>
                    </div>
                    <!-- app-breadcrumb end -->


                  </div>
                </div>
              </div>
            </div>
          </ng-container>




          <section class="app-user-view-account">
            <div class="row">
                <!-- User Sidebar -->
                <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                    <!-- User Card -->
                    <div class="card">
                        <div class="card-body">
                            <div class="user-avatar-section">
                                <div class="d-flex align-items-center flex-column">
                                    <!-- <img class="img-fluid rounded mt-3 mb-2" src="../../../assets/images/portrait/small/avatar-s-2.jpg" height="110" width="110" alt="User avatar" /> -->
                                    <div *ngIf="apiData.avatar?.length > 0; else customAvatar">
                                    <img
                                        class="img-fluid rounded mt-3 mb-2"
                                        src="{{_BackendUrl +'img/users/'+ apiData.avatar}}"
                                        height="110"
                                        width="110"
                                        alt="User avatar"
                                    />
                                    </div>
                                    <ng-template #customAvatar>
                                    <div
                                        class="mb-2 bg-light-success rounded">
                                        <div class="rounded p-3">{{ apiData.nom |uppercase | initials }}</div>
                                    </div>
                                    </ng-template>
                                    <div class="user-info text-center">
                                        <h4>{{ apiData.nom}}</h4>
                                        <span class="badge bg-light-secondary">{{ apiData.email }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-flex justify-content-around my-2 pt-75">
                                <div class="d-flex align-items-start mr-2">
                                    <span class="badge bg-light-primary p-75 rounded">
                                        <i data-feather="shopping-bag" class="font-medium-2"></i>
                                    </span>
                                    <div class="ml-75">
                                        <h4 class="mb-0">{{total_factures | currency:currency:'symbol'}}</h4>
                                        <small>Total factures</small>
                                    </div>
                                </div>
                                <div class="d-flex align-items-start">
                                    <span class="badge bg-light-primary p-75 rounded">
                                        <i data-feather="loader" class="font-medium-2"></i>
                                    </span>
                                    <div class="ml-75">
                                        <h4 class="mb-0">{{ total_reste | currency:currency:'symbol' }}</h4>
                                        <small>Total Restant</small>
                                    </div>
                                </div>
                            </div> -->
                            <h4 class="fw-bolder border-bottom pb-50 mb-1">Détails</h4>
                            <div class="info-container">
                                <ul class="list-unstyled">
                                    <li class="mb-75">
                                        <span class="fw-bolder mr-25">Nom et prénom:</span>
                                        <span>{{ apiData.nom}}</span>
                                    </li>
                                    <li class="mb-75">
                                        <span class="fw-bolder mr-25">Email:</span>
                                        <span>{{ apiData.email}}</span>
                                    </li>
                                    <li class="mb-75">
                                        <span class="fw-bolder mr-25">Téléphone:</span>
                                        <span>{{ apiData.telephone}}</span>
                                    </li>
                                    <li class="mb-75">
                                        <span class="fw-bolder mr-25">Statut:</span>
                                        <span class="badge bg-light-success">Activé</span>
                                    </li>
                              
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /User Card -->
                </div>
                <!--/ User Sidebar -->

                <!-- User Content -->
                <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                    <!-- User Pills -->
                    <ul ngbNav #nav="ngbNav" class="nav nav-pills mb-2">
                        <li ngbNavItem>
                            <a ngbNavLink>
                                <i data-feather="file" class="font-medium-3 mr-50"></i>
                                <span class="fw-bold">Abonnements</span>
                            </a>
                            <ng-template ngbNavContent>
                                 <!-- Project table -->
                                <div class="card">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-header">Liste des Abonnements</h4>
                                        <button  (click)="toggleSidebar('new-abonnement-sidebar');" class="btn btn-primary mr-1">
                                            Ajouter
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table datatable-project">
                                            <thead>
                                                <tr>
                                                    <th>Abonnement</th>
                                                    <th class="text-nowrap">Prix</th>
                                                    <th>Statut</th>
                                                    <th>Date début</th>
                                                    <th>Durée</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let row of apiDataAb">
                                                <td>
                                                    <div class="d-flex justify-content-left align-items-center">
                                                        <div class="avatar-wrapper">
                
                                                            <div
                                                                class="avatar mr-1"
                                                                [ngClass]="{
                                                                    'bg-light-primary': ['a','b','c','d','e','0','5'].includes((row.nom[0]).toLowerCase()),
                                                                    'bg-light-success': ['f','g','h','i','j','1','6'].includes((row.nom[0]).toLowerCase()),
                                                                    'bg-light-danger': ['k','l','m','n','o','2','7'].includes((row.nom[0]).toLowerCase()),
                                                                    'bg-light-warning': ['p','q','r','s','t','3','8'].includes((row.nom[0]).toLowerCase()),
                                                                    'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((row.nom[0]).toLowerCase())
                                                                }"
                                                                >
                                                                <div class="avatar-content">{{ (row.nom |uppercase) | initials }}</div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="d-flex flex-column">
                                                            <span class="text-truncate fw-bolder">{{ row.nom }}</span>
                                                            <small class="text-muted">{{ row.mensuel ? 'Mensuel' : 'Annuel' }}</small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td *ngIf="row.mensuel">{{ row.plan_mensuel_prix_mois  | currency:currency:'symbol' }}</td>
                                                <td *ngIf="!row.mensuel">{{ row.plan_annuel_prix_an  | currency:currency:'symbol' }}</td>
                                                <td>
                                                    <span class="badge rounded-pill"
                                                        [ngClass]="{
                                                        'badge-light-danger': row.statut == 'Expiré',
                                                        'badge-light-secondary': row.statut == 'Terminé',
                                                        'badge-light-success': row.statut == 'En cour'
                                                        }"
                                                    >
                                                        {{ row.statut }}
                                                    </span>
                                                </td>
                                                <td> {{ row.createdAt | date }}</td>
                                                <td> {{ row.duree }} jours</td>
                                                <td class="p-0">
                                                    <a class="mr-1" (click)="selectAbonnement(row.id);toggleSidebar('edit-abonnement-sidebar');" container="body" placement="top" ngbTooltip="Modifier">
                                                        <i size="18" data-feather="edit"></i>
                                                    </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- /Project table -->

                            </ng-template>
                        </li>                      
                    </ul>
                    <!--/ User Pills -->

                    <div [ngbNavOutlet]="nav" class="mt-1"></div>

                   
                </div>
                <!--/ User Content -->
            </div>
          </section>
   



  </div>
</div>






<core-sidebar
class="modal modal-slide-in sidebar-todo-modal fade"
name="new-abonnement-sidebar"
overlayClass="modal-backdrop"
>
<!-- <app-new-grille-sidebar></app-new-grille-sidebar> -->

<div class="slideout-content">
    <!-- Modal to add new user starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
      <div class="modal-dialog">
        <form class="add-new-user modal-content pt-0" [formGroup]="formAbonnement" (ngSubmit)="ajouter_abonnement()">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="toggleSidebar('new-abonnement-sidebar')"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">Ajouter un abonnement</h5>
          </div>
          <div class="modal-body flex-grow-1">

            <div class="form-group">
                <label class="form-label">Abonnement</label>
                <select formControlName="selectedAbonnement" class="form-control" (change)="changer_abonnement()">
                    <option value=""></option>
                    <option *ngFor="let p of liste_abonnements" value="{{p.id}}">{{p.nom}}  - {{ p.plan_mensuel_prix_mois | currency:currency:'symbol'}}</option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label">Annuel / Mensuel</label>
                <select formControlName="mensuel" class="form-control" (change)="changer_type_ab()">
                    <option value="false">Annuel</option>
                    <option value="true">Mensuel</option>
                </select>
            </div>

            <div class="form-group">
                <label>Date début</label>
                <input type="date" formControlName="debut" class="form-control" placeholder="Date début">
            </div>
            
            <div class="form-group">
                <label>Durée (jours)</label>
                <input type="number" formControlName="duree" class="form-control" placeholder="Durée">
            </div>

            <div class="form-group">
              <label>nb_devis</label>
              <input type="number" formControlName="nb_devis" class="form-control" placeholder="nb_devis">
            </div>

            <div class="form-group">
                <label>nb_commandes_clients</label>
                <input type="number" formControlName="nb_commandes_clients" class="form-control" placeholder="nb_commandes_clients">
            </div>

            <div class="form-group">
                <label>nb_bon_livraisons</label>
                <input type="number" formControlName="nb_bon_livraisons" class="form-control" placeholder="nb_bon_livraisons">
            </div>

            <div class="form-group">
                <label>nb_factures</label>
                <input type="number" formControlName="nb_factures" class="form-control" placeholder="nb_factures">
            </div>

            <div class="form-group">
                <label>nb_avoirs</label>
                <input type="number" formControlName="nb_avoirs" class="form-control" placeholder="nb_avoirs">
            </div>

            <div class="form-group">
                <label>nb_factures_fournisseurs</label>
                <input type="number" formControlName="nb_factures_fournisseurs" class="form-control" placeholder="nb_factures_fournisseurs">
            </div>
            <div class="form-group">
                <label>nb_users</label>
                <input type="number" formControlName="nb_users" class="form-control" placeholder="nb_users">
            </div>

            <div class="form-group">
                <label>nb_entreprises</label>
                <input type="number" formControlName="nb_entreprises" class="form-control" placeholder="nb_entreprises">
            </div>
            <div class="form-group">
                <label>nb_produits</label>
                <input type="number" formControlName="nb_produits" class="form-control" placeholder="nb_produits">
            </div>

            <div class="form-group">
                <label>nb_clients</label>
                <input type="number" formControlName="nb_clients" class="form-control" placeholder="nb_clients">
            </div>
            <div class="form-group">
                <label>nb_fournisseurs</label>
                <input type="number" formControlName="nb_fournisseurs" class="form-control" placeholder="nb_fournisseurs">
            </div>

            <div class="form-group">
                <label>plan_mensuel_prix_mois</label>
                <input type="number" formControlName="plan_mensuel_prix_mois" class="form-control" placeholder="plan_mensuel_prix_mois">
            </div>
            <div class="form-group">
                <label>plan_annuel_prix_mois</label>
                <input type="number" formControlName="plan_annuel_prix_mois" class="form-control" placeholder="plan_annuel_prix_mois">
            </div>

            <div class="form-group">
                <label>plan_annuel_prix_an</label>
                <input type="number" formControlName="plan_annuel_prix_an" class="form-control" placeholder="plan_annuel_prix_an">
            </div>

            
            <ngb-alert *ngIf="submittedAbonnement && formAbonnement.invalid" [type]="'danger'" [dismissible]="false">
                <div class="alert-body"><strong>Erreur!</strong> Tous les champs est obligatoire!</div>
            </ngb-alert>
       
  

            <button type="submit" class="btn btn-primary mr-1" rippleEffect>Ajouter</button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              (click)="toggleSidebar('new-abonnement-sidebar')"
              rippleEffect
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Modal to add new user Ends-->
  </div>
  

  
</core-sidebar>






<core-sidebar
class="modal modal-slide-in sidebar-todo-modal fade"
name="edit-abonnement-sidebar"
overlayClass="modal-backdrop"
>
<!-- <app-new-grille-sidebar></app-new-grille-sidebar> -->

<div class="slideout-content">
    <!-- Modal to add new user starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
      <div class="modal-dialog">
        <form class="add-new-user modal-content pt-0" [formGroup]="formEditAbonnement" (ngSubmit)="modifier_abonnement()">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="toggleSidebar('edit-abonnement-sidebar')"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">Modifier abonnement</h5>
          </div>
          <div class="modal-body flex-grow-1">

            <div class="form-group">
                <label class="form-label">Abonnement</label>
                <select formControlName="selectedAbonnement" class="form-control" (change)="changer_abonnement()">
                    <option value=""></option>
                    <option *ngFor="let p of liste_abonnements" value="{{p.id}}">{{p.nom}}  - {{ p.plan_mensuel_prix_mois | currency:currency:'symbol'}}</option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-label">Annuel / Mensuel</label>
                <select formControlName="mensuel" class="form-control" (change)="changer_type_ab()">
                    <option value="false">Annuel</option>
                    <option value="true">Mensuel</option>
                </select>
            </div>

            <div class="form-group">
                <label>Date début</label>
                <input type="date" formControlName="debut" class="form-control" placeholder="Date début">
            </div>
            
            <div class="form-group">
                <label>Durée (jours)</label>
                <input type="number" formControlName="duree" class="form-control" placeholder="Durée">
            </div>

            <div class="form-group">
              <label>nb_devis</label>
              <input type="number" formControlName="nb_devis" class="form-control" placeholder="nb_devis">
            </div>

            <div class="form-group">
                <label>nb_commandes_clients</label>
                <input type="number" formControlName="nb_commandes_clients" class="form-control" placeholder="nb_commandes_clients">
            </div>

            <div class="form-group">
                <label>nb_bon_livraisons</label>
                <input type="number" formControlName="nb_bon_livraisons" class="form-control" placeholder="nb_bon_livraisons">
            </div>

            <div class="form-group">
                <label>nb_factures</label>
                <input type="number" formControlName="nb_factures" class="form-control" placeholder="nb_factures">
            </div>

            <div class="form-group">
                <label>nb_avoirs</label>
                <input type="number" formControlName="nb_avoirs" class="form-control" placeholder="nb_avoirs">
            </div>

            <div class="form-group">
                <label>nb_factures_fournisseurs</label>
                <input type="number" formControlName="nb_factures_fournisseurs" class="form-control" placeholder="nb_factures_fournisseurs">
            </div>
            <div class="form-group">
                <label>nb_users</label>
                <input type="number" formControlName="nb_users" class="form-control" placeholder="nb_users">
            </div>

            <div class="form-group">
                <label>nb_entreprises</label>
                <input type="number" formControlName="nb_entreprises" class="form-control" placeholder="nb_entreprises">
            </div>
            <div class="form-group">
                <label>nb_produits</label>
                <input type="number" formControlName="nb_produits" class="form-control" placeholder="nb_produits">
            </div>

            <div class="form-group">
                <label>nb_clients</label>
                <input type="number" formControlName="nb_clients" class="form-control" placeholder="nb_clients">
            </div>
            <div class="form-group">
                <label>nb_fournisseurs</label>
                <input type="number" formControlName="nb_fournisseurs" class="form-control" placeholder="nb_fournisseurs">
            </div>

            <div class="form-group">
                <label>plan_mensuel_prix_mois</label>
                <input type="number" formControlName="plan_mensuel_prix_mois" class="form-control" placeholder="plan_mensuel_prix_mois">
            </div>
            <div class="form-group">
                <label>plan_annuel_prix_mois</label>
                <input type="number" formControlName="plan_annuel_prix_mois" class="form-control" placeholder="plan_annuel_prix_mois">
            </div>

            <div class="form-group">
                <label>plan_annuel_prix_an</label>
                <input type="number" formControlName="plan_annuel_prix_an" class="form-control" placeholder="plan_annuel_prix_an">
            </div>

            
            <ngb-alert *ngIf="submittedEditAbonnement && formEditAbonnement.invalid" [type]="'danger'" [dismissible]="false">
                <div class="alert-body"><strong>Erreur!</strong> Tous les champs est obligatoire!</div>
            </ngb-alert>
       
  

            <button type="submit" class="btn btn-primary mr-1" rippleEffect>Modifier</button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              (click)="toggleSidebar('edit-abonnement-sidebar')"
              rippleEffect
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Modal to add new user Ends-->
  </div>
  

  
</core-sidebar>

<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://www.ventexy.com/" target="_blank">Ventexy</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    
    <div>        
      <span href="#" class="pointer mr-2" target="_blank">License</span>
      <span href="#" target="_blank" class="pointer mr-2">Documentation</span>
      <span href="#" target="_blank" class="pointer">Support</span>      
    </div>

  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>


export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: {
        STATITSIQUES : 'Statistiques',
        VENTE : 'Sales',
        DEVIS : 'Estimate',
        COMMANDECLIENT : 'Client Order',
        BONLIVRAISON : 'Delivery Note',
        BONSORTIE : 'Issue Note',
        FACTURECLIENT : 'Invoice',
        TURBOFACTURE : 'Invoice Turbo',
        AVOIRCLIENT : "Credit Note",
        ACHATS : 'Purchases',
        DEPENSES : 'Expenses',
        DEPENSESENATTENTE : 'Pending Expenses',
        BONCOMMANDE : 'Purchase Order',
        BONRECEPTION : 'Receipt Note',
        FACTUREFOURNISSEUR : 'Purchase Invoice',
        PRODUITS : 'Products',
        GROUPESPRODUITS : 'Product Groups',
        CATEGORIES : "categories",
        CLIENTS : "Clients",
        LISTECLIENTS : "Clients",
        FOURNISSEURS : "Suppliers",
        CONFIGURATION : "Configuration",
        COLLAPSIBLE: 'Dashboard',
        ACCUEIL : 'Home',
        USERS : 'Users',
        ROLES : 'Roles',
        PERMISSIONS : 'Permissions',
        MODULES : 'MODULES',
        ENTREPRISE : 'Companies',
        MESENTREPRISE : 'My Companies',
        NOUVELLEENTREPRISE : 'New Company',
        NEW:'New',
        STOCK : 'Stock',
        GESTIONSTOCK : 'Stock Management',
        INVENTAIRE : 'Inventory',
        PAIEMENTS : 'Payments',
        REGLEMENTS : 'Settlement',
        RETARDPAIEMENTS : 'Late Payment',
        RELANCES : 'Recovery',
        COMINGDUE : 'Coming Due',
        RELANCEAUTO : 'Auto Reminder',
        RELANCESLETTRES : 'Reminder Letters',
        ACOMPTES : 'Advance',
        RECURRENCES : 'Recurrence',
        GROUPBL : 'Group Delivery Note',
        GROUPBR : 'Group Receipt Note',
        CHEQUES : 'Check Management',
        MOVEMENTSSTOCK : 'Stock Movements',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce',
        VEHICULES : 'Vehicles',
        LISTEVEHICULES : 'Vehicles List',
        LISTECONDUCTEURS : 'Driver list',
        CRM : 'CRM',
        PIPELINES : 'Pipelines',
        TASKS : 'Tasks',
        CALENDRIER : 'Calendar',
        GESTIONCHEQUES : 'Checks',
        PROSPECTS : 'Prospects',
        CONTACTS : 'Contacts',
        FACTURATION : 'Invoicing',
        CHEQUESRECUS : 'Checks Received',
        CHEQUESEMIS : 'Checks Issued',
      },
    }
  }
}

import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MyPermissionsService } from 'app/auth/service/my-permissions.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { User } from 'app/auth/models/user';
import { ListCompaniesService } from './list-companies.service';
import { log } from 'console';
import { PaysService } from 'app/service/pays.service';

@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListCompaniesComponent implements OnInit {

 
    
  private _BackendUrl = environment._BackendUrl;
  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];
  public rows: any;
  public exportCSVData;
  public selected = [];


  public SelectionType = SelectionType;

  public selectedOption = 100;
  public searchValue = '';

  public ColumnMode = ColumnMode;
  
  public selectStatus: any = [
    { name: 'Tous', value: '' },
    { name: 'Essai Gratuit', value: 'Essai Gratuit' },
    { name: 'Standard', value: 'Standard' },
    { name: 'Basique', value: 'Basique' },
    { name: 'Premium', value: 'Premium' },
    { name: 'Expiré', value: 'Expiré' },
  ];

  public selectedStatus = [];
  public tempFilterData;
  public previousStatusFilter = '';


  formEdit: FormGroup;
  submitted = false;
  currency = 'EUR'

  formEditPrix: FormGroup;
  grilles = []
  
  @ViewChild(DatatableComponent) table: DatatableComponent;
  selectedRow: any;
  selectedRowCSV = []
  submittedFP = false
  top_produits: any;
  permissions: any;

  
  public currentUser: User;
  public isAdmin: boolean;
  

  limitMaxMessage: string;
  dataCompany: any;


  pays = []
  dataUser: any[];
  nbConfirmDelete = 0;
  selectedId: any;
  liste_demandes_demo: any;
  statistiques: any;
  users_connecte = 0


  liste_demandes_devis;

constructor(
  private _companiesService: ListCompaniesService,
  private _coreSidebarService: CoreSidebarService,
  private modalService: NgbModal,
  private toastr: ToastrService,
  private _pays:PaysService,
  private _formBuilder: FormBuilder,
) {
  this._unsubscribeAll = new Subject();
}




ngOnInit(): void {

  this._companiesService.list.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    this.rows = response;
    this.tempData = this.rows;
    this.exportCSVData = this.rows;

    this.rows.forEach(row => {
      if( this.estAujourdhui(row.derniere_connexion) ) this.users_connecte ++
    });
  });



  this._companiesService.liste_demandes_demo().subscribe(response => {
    this.liste_demandes_demo = response;
  });

  this._companiesService.get_demandes_devis_abonnement().subscribe(response => {
    this.liste_demandes_devis = response;
  });

  this._companiesService.get_nbr_statiqtiques_website().subscribe(response => {
    this.statistiques = response[0];
  });

  
  
  this.pays = this._pays.pays

}



getAbonnementEnCour(ab){
  let p = ab.filter(row => { return row.statut == 'En cour'});
  // console.log(p);
  
  if(p?.length > 0){
    return p[0].statut
  }else{
    return "Expiré"
  } 
}

getAbonnementNom(ab){
  let p = ab.filter(row => { return row.statut == 'En cour'});
  // console.log(p);
  
  if(p?.length > 0){
    return p[0].nom
  }else{
    return "Expiré"
  } 
}

getPays(id){
  if( id == '') return ''
  let p =  this.pays.filter(row => { return row.id == id})

  if(p?.length > 0) return p[0].country

  return ''
}

getPaysCode(id){
  if( id == '') return ''
  let p =  this.pays.filter(row => { return row.id == id})

  if(p?.length > 0) return p[0].code

  return ''
}


estAujourdhui(derniereConnexion: Date): boolean {
  const aujourdhui = new Date();
  const derniereConnexionDate = new Date(derniereConnexion);

  // Récupère la date d'aujourd'hui sans l'heure
  const aujourdhuiSansHeure = new Date(aujourdhui.getFullYear(), aujourdhui.getMonth(), aujourdhui.getDate());

  // Récupère la date de la dernière connexion sans l'heure
  const derniereConnexionSansHeure = new Date(derniereConnexionDate.getFullYear(), derniereConnexionDate.getMonth(), derniereConnexionDate.getDate());

  
  return aujourdhuiSansHeure.getTime() === derniereConnexionSansHeure.getTime();


}

toggleSidebar(name): void {
  this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
}

modalOpenDanger(modalDanger,id) {
  this.selectedId = id
  this.modalService.open(modalDanger, {
    windowClass: 'modal modal-danger'
  });
}

liste_demandes(modalDanger){
  this.modalService.open(modalDanger, {
    windowClass: 'modal modal-primary',
    size: 'lg'
  });
}

liste_demandes_devis_ab(modalDanger){
  this.modalService.open(modalDanger, {
    windowClass: 'modal modal-primary z-index9999999',
    size: 'xl'
  });
}


supprimer_demandes_devis(id){

  this._companiesService.supprimer_demandes_devis({id:id}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success('Demande de devis supprimé avec succés.', 'Succés!', {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          
          this._companiesService.get_demandes_devis_abonnement().subscribe(response => {
            this.liste_demandes_devis = response;
          });
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}


update_demandes_devis(id,t){

  this._companiesService.update_demandes_devis({id:id,traite:t}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success('Demande de devis modifié avec succés.', 'Succés!', {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          
          this._companiesService.get_demandes_devis_abonnement().subscribe(response => {
            this.liste_demandes_devis = response;
          });
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}


supprimer_demandes_demo(id){

  this._companiesService.supprimer_demandes_demo({id:id}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success('Demande supprimé avec succés.', 'Succés!', {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          
          this._companiesService.liste_demandes_demo().subscribe(response => {
            this.liste_demandes_demo = response;
          });
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}


update_demandes_demo(id,t){

  this._companiesService.update_demandes_demo({id:id,traite:t}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success('Demande modifié avec succés.', 'Succés!', {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          
          this._companiesService.liste_demandes_demo().subscribe(response => {
            this.liste_demandes_demo = response;
          });
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}


supprimer(id){

  this.nbConfirmDelete ++

  if(this.nbConfirmDelete < 5 ) return

  this._companiesService.supprimer_client_par_admin({id:id}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success('User supprimé avec succés.', 'Succés!', {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          this.nbConfirmDelete = 0
          this._companiesService.get_companies()
          this.modalService.dismissAll()
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}




details_user(modalLG) {
  this.dataUser = []

  this._companiesService.details_user({id:this.selectedRow.id}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){

          this.dataUser = res.results

          console.log( this.dataUser);
          
          this.modalService.open(modalLG, {
            size: 'xl',
            windowClass:'z-index9999999'
          });
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )

 
}



modalOpenTopP(modalLG) {
  this.modalService.open(modalLG, {
    size: 'lg'
  });
  
}


filterUpdate(event) {
  // Reset ng-select on search
  this.selectedStatus = this.selectStatus[0];

  const val = event.target.value.toLowerCase();

  // filter our data
  const temp = this.tempData.filter(function (d) {
    return d.nom.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.email.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.telephone.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.nom.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.devise.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.adresse.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.ville.toLowerCase().indexOf(val) !== -1 ||
           d.entreprises[0]?.pays.toLowerCase().indexOf(val) !== -1 ||
           d.createdAt.toLowerCase().indexOf(val) !== -1 || !val;
  });

  // update the rows
  this.rows = temp;
  // Whenever the filter changes, always go back to the first page
  this.table.offset = 0;
}


filterByStatus(event) {
  const filter = event ? event.value : 0;
  
  this.previousStatusFilter = filter;
  this.tempFilterData = this.filterRows(filter);
  this.rows = this.tempFilterData;
}

filterRows(statusFilter): any[] {
  // Reset search on select change
  this.searchValue = '';

  // statusFilter = parseInt(statusFilter)

  return this.tempData.filter(row => {
    console.log(row);
    
    const isPartialNameMatch = this.getAbonnementNom(row.abonnements).indexOf(statusFilter) !== -1 || !statusFilter;
    return isPartialNameMatch;
  });
}


// ta3tik les données mta3 el ligne eli 7atit fou9ou souris fl datatable
onActivate(event) {  
  this.selectedRow = event.row
  this.selectedRowCSV = []
  this.selectedRowCSV .push(event.row)
}

onSelect({ selected }) {
   this.selected = selected;
}




modalOpenDanger22 (modalDanger) {
  this.modalService.open(modalDanger, {
    windowClass: 'modal modal-success'
  });
}

mailMultiple(modalDanger){
  let ids =  this.selected.map(item => item.id)

  console.log();
  
  
  this._companiesService.mailMultiple({ids:ids}).pipe(first()).subscribe(
    res => {
        if(res.msg == "success"){
          this.toastr.success("Email envoyé avec succés","Succés.", {
            toastClass: 'toast ngx-toastr',
            closeButton: true
          });
          this.modalService.dismissAll()
          this.selected = []
        }
    },
    err =>{
      console.log(err);
      
      this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
    }
  )
}





ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.next();
  this._unsubscribeAll.complete();
  
}

}
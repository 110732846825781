import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';



import { CsvModule } from '@ctrl/ngx-csv';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';
import { ListCompaniesComponent } from './list-companies/list-companies.component';
import { ListCompaniesService } from './list-companies/list-companies.service';
import { VisiteursAppComponent } from './visiteurs-app/visiteurs-app.component';
import { VisiteursSitewebComponent } from './visiteurs-siteweb/visiteurs-siteweb.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { DetailsUserComponent } from './details-user/details-user.component';
import { DetailsUserService } from './details-user/details-user.service';
import { VisiteursSitewebService } from './visiteurs-siteweb/visiteurs-siteweb.service';



// routing
const routes: Routes = [
  {
    path: 'list-companies',
    component: ListCompaniesComponent,
    resolve: {
      uls: ListCompaniesService
    },
    data: { animation: 'ListCompaniesComponent' }
    ,canActivate: [AuthGuard]
  },
  {
    path: 'details/:id',
    component: DetailsUserComponent,
    resolve: {
      uls: DetailsUserService
    },
    data: { animation: 'DetailsUserComponent' }
    ,canActivate: [AuthGuard]
  },
  // {
  //   path: 'visiteurs-app',
  //   component: VisiteursAppComponent,
  //   // resolve: {
  //   //   uls: VisiteursSitewebService
  //   // },
  //   // data: { animation: 'VisiteursAppComponent' }
  //   // ,canActivate: [AuthGuard]
  // },
  {
    path: 'visiteurs-siteweb',
    component: VisiteursSitewebComponent,
    resolve: {
      uls: VisiteursSitewebService
    },
    data: { animation: 'VisiteursSitewebComponent' }
    ,canActivate: [AuthGuard]
  },

];

@NgModule({
  declarations: [VisiteursSitewebComponent,ListCompaniesComponent,VisiteursAppComponent, DetailsUserComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    CsvModule
  ],
  providers: [VisiteursSitewebService,ListCompaniesService,DetailsUserService]
})
export class CompanyModule {}

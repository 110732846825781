import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
 


       
  {
    id: 'forms-table',
    type: 'section',
    title: 'Modules',
    translate: 'MENU.DASHBOARD.MODULES',
    icon: 'file-text',
    etat:'enable',
  },


    // Dashboard
    {
      id: 'dashboard',
      title: 'Accueil',
      translate: 'MENU.DASHBOARD.ACCUEIL',
      type: 'collapsible',
      // role: ['Admin'], //? To hide collapsible based on user role
      icon: 'home',
      etat:'enable',
      children: [
        // {
        //   id: 'tableau_de_board',
        //   title: 'Tableau de board',
        //   translate: 'MENU.DASHBOARD.COLLAPSIBLE',
        //   type: 'item',
        //   icon: 'circle',
        //   etat:'enable',
        //   url: 'dashboard'
        // },
        // {
        //   id: 'mes_entreprises',
        //   title: 'Mes Entreprises',
        //   translate: 'MENU.DASHBOARD.MESENTREPRISE',
        //   type: 'item',
        //   icon: 'circle',
        //   etat:'enable',
        //   url: 'account/companies'
        // },
        {
          id: 'tableau_de_board',
          title: 'Tableau de board',
          translate: 'MENU.DASHBOARD.COLLAPSIBLE',
          type: 'item',
          icon: 'circle',
          etat:'enable',
          url: 'home'
        },

        {
          id: 'liste-entreprises',
          title: 'Liste Entreprises',
          translate: 'MENU.DASHBOARD.LE',
          type: 'item',
          icon: 'circle',
          etat:'enable',
          url: 'list-companies'
        },

        {
          id: 'tableau_de_board',
          title: 'Visiteurs App',
          translate: 'MENU.DASHBOARD.VAPP',
          type: 'item',
          icon: 'circle',
          etat:'enable',
          url: 'visiteurs-app'
        },

        {
          id: 'tableau_de_board2',
          title: 'Visiteurs SiteWeb',
          translate: 'MENU.DASHBOARD.VSITE',
          type: 'item',
          icon: 'circle',
          etat:'enable',
          url: 'visiteurs-siteweb'
        },

      ]
    },

  ]


 
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntil,first } from 'rxjs/operators';
import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html'
})
export class NavbarSearchComponent implements OnInit {
  // Public
  public searchText = '';
  public openSearchRef = false;
  public activeIndex = 0;
  public apiData;
  clients = []
  fournisseurs = []
  factures = []
  devis= []
  commande_client= []
  bon_livraison= []
  facture_achat= []
  public pageSearchLimit;


  pages = []
  files = []
  contacts = []

  private _BackendUrl = environment._BackendUrl;

  // Decorators
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;

  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    if (event.target.className === 'content-overlay') {
      this.removeOverlay();
      this.openSearchRef = false;
      this.searchText = '';
    }
  }

  /**
   *
   * @param document
   * @param router
   * @param _searchService
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _elementRef: ElementRef,
    private router: Router,
    private toastr: ToastrService,
    public _searchService: SearchService
  ) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  
  
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      let current_item = this._pageListElement.nativeElement.getElementsByClassName('current_item');
      current_item[0]?.children[0].click();
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this._searchService.onIsBookmarkOpenChange.next(false);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

    if (this.openSearchRef === false) {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
      this.searchText = '';
    }
  }

  /**
   * Search Update
   *
   * @param event
   */
  searchUpdate(event) {
    const val = event.target.value.toLowerCase();
    if (val !== '') {
      this.document.querySelector('.app-content').classList.add('show-overlay');
      this.searchData(val)
    } else {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
    }
    
    this.autoSuggestion(event);
  }

  searchData(val){
    this._searchService.searchData({search:val}).subscribe(
      res => {
          if(res.msg == "success"){
            this.apiData = res.results;
            this.clients = this.apiData.clients
            this.fournisseurs = this.apiData.fournisseurs
            this.factures = this.apiData.factures
            this.devis = this.apiData.devis
            this.commande_client = this.apiData.commande_client
            this.bon_livraison = this.apiData.bon_livraison
            this.facture_achat = this.apiData.facture_achat
            
          }
      },
      err =>{
        console.log(err);
        
        this.toastr.error('Failed to load resource: the server responded with a status of 500 (Internal Server Error)', 'Erreur!', {
          toastClass: 'toast ngx-toastr',
          closeButton: true
        });
      }
    )
  }


  ngOnInit(): void {
    // this._searchService.onApiDataChange.subscribe(res => {
      // this.apiData = res;
      // this.pages = this.apiData[0].data;
      // this.pageSearchLimit = this.apiData[0].searchLimit;
      // this.files = this.apiData[1].data;
      // this.contacts = this.apiData[2].data;
    // });
  }
}

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
      


        <ng-template #modalDelete let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">Supprimer user</h4>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" tabindex="0" ngbAutofocus>
            <p class="mt-1 mb-2">Voulez-vous supprimer cet user ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="supprimer(selectedId)" rippleEffect>Supprimer <span *ngIf="nbConfirmDelete>0">{{nbConfirmDelete}}</span></button>
          </div>
        </ng-template>


        <ng-template #modalMailMulti let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">Envoyer email</h4>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" tabindex="0" ngbAutofocus>
            <p class="mt-1 mb-2">Voulez-vous envoyer des email pour les clients séléctionnés?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="mailMultiple(modalMailMulti)" rippleEffect>Envoyer</button>
          </div>
        </ng-template>



        <ng-template #modalDetails let-modal>
          <div class="modal-header bg-transparent">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-5 px-sm-4 mx-50" tabindex="0" ngbAutofocus>
              <div class="text-center mb-4">
                  <h1 class="role-title">Détails user</h1>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing inus quidem esse sit exerci.</p>
              </div>
  
              <!-- Add role form -->
                <div class="row">
                  <div class="col-12">
                      <!-- Permission table --> 
                      
                      <div *ngFor="let row of dataUser" class="table-responsive">

                        <div>
                          <ngb-alert [type]="'success'" [dismissible]="false">
                            <div class="alert-body">{{'#' + row.company.id}} | Nom : <strong>{{row.company.nom}}</strong> | Devise : <i class=" mr-50 flag-icon flag-icon-{{row.company.devise[0]+row.company.devise[1] | lowercase  }}"></i> {{ row.company.devise  }}  | Pays : <i class=" mr-50 flag-icon flag-icon-{{getPaysCode(row.company.pays) | lowercase  }}"></i> {{ getPays(row.company.pays)  }}</div>
                          </ngb-alert>
                        </div>

                          <table class="table border">
                            <thead>
                              <tr>
                                <th>Nb devis</th>
                                <th>Nb bon commande</th>
                                <th>Nb bon livraison</th>
                                <th>Nb factures</th>
                                <th>Nb avoirs</th>
                                <th>Nb produits</th>
                                <th>Nb clients</th>
                                <th>Nb fournisseurs</th>
                                <th>Nb factures achats</th>
                                <th>Nb depenses</th>
                                <th>Nb users</th>
                              </tr>
                            </thead>
                              <tbody>
                                  <tr>
                                      <td>{{row.data.devis}}</td>
                                      <td>{{row.data.commande_client}}</td>
                                      <td>{{row.data.bon_livraison}}</td>
                                      <td>{{row.data.facture}}</td>
                                      <td>{{row.data.avoir}}</td>
                                      <td>{{row.data.produits}}</td>
                                      <td>{{row.data.clients}}</td>
                                      <td>{{row.data.fournisseurs}}</td>
                                      <td>{{row.data.facture_achat}}</td>
                                      <td>{{row.data.depenses}}</td>
                                      <td>{{row.data.users}}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <!-- Permission table -->
                  </div>
                  <div class="col-12 text-center mt-2">
                      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">
                          Fermer
                      </button>
                  </div>
                </div>
              <!--/ Add role form -->
          </div>
        </ng-template>


        <ng-template #modalDemandes let-modal>
          <div class="modal-header bg-transparent">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-5 px-sm-4 mx-50" tabindex="0" ngbAutofocus>
              <div class="text-center mb-4">
                  <h1 class="role-title">Demandes de demos</h1>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing inus quidem esse sit exerci.</p>
              </div>
  
              <!-- Add role form -->
                <div class="row">
                  <div class="col-12">
                      <!-- Permission table --> 
                      
                      <div class="table-responsive">


                          <table class="table border">
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Télephone</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                              <tbody>
                                  <tr *ngFor="let row of liste_demandes_demo">
                                      <td>{{row.id}}</td>
                                      <td>{{row.telephone}}</td>
                                      <td>{{row.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                                      <td>
                                        <div class="">
                                          <button  (click)="supprimer_demandes_demo(row.id)" class="btn btn-danger btn-sm mb-50" style="width: 150px;">Supprimer</button>
                                          <button *ngIf="!row.traite" (click)="update_demandes_demo(row.id,true)"  class="btn btn-success btn-sm mb-50" style="width: 150px;">Rendre traiter</button>
                                          <button *ngIf="row.traite" (click)="update_demandes_demo(row.id,false)"  class="btn btn-warning btn-sm mb-50" style="width: 150px;">Rendre non traiter</button>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>


                          <ngb-alert *ngIf="liste_demandes_demo?.length == 0" [type]="'info'" [dismissible]="false">
                            <div class="alert-body">Aucune demande de demo </div>
                          </ngb-alert>

                      </div>
                      <!-- Permission table -->
                  </div>
                  <div class="col-12 text-center mt-2">
                      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">
                          Fermer
                      </button>
                  </div>
                </div>
              <!--/ Add role form -->
          </div>
        </ng-template>

        <ng-template #modalDemandesDevis let-modal>
          <div class="modal-header bg-transparent">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-5 px-sm-4 mx-50" tabindex="0" ngbAutofocus>
              <div class="text-center mb-4">
                  <h1 class="role-title">Demandes de devis</h1>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing inus quidem esse sit exerci.</p>
              </div>
  
              <!-- Add role form -->
                <div class="row">
                  <div class="col-12">
                      <!-- Permission table --> 
                      
                      <div class="table-responsive">


                          <table class="table table-bordered border">
                            <thead>
                              <tr>
                                <th>Client</th>
                                <th>Abonnement demandé :</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                              <tbody>
                                  <tr *ngFor="let row of liste_demandes_devis">
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div *ngIf="row.superUser?.avatar.length > 0; else customAvatar222">
                                            <img
                                              class="rounded-circle mr-1"
                                              src="{{ _BackendUrl +'img/users/'+ row.superUser?.avatar }}"
                                              height="32"
                                              width="32"
                                              alt="datatable-avatar"
                                            />
                                          </div>
                                          <ng-template #customAvatar222>
                                            <div
                                              class="avatar mr-1 ml-0"
                                              [ngClass]="{
                                                'bg-light-primary': ['a','b','c','d','e','0','5'].includes((row.superUser?.nom[0]).toLowerCase()),
                                                'bg-light-success': ['f','g','h','i','j','1','6'].includes((row.superUser?.nom[0]).toLowerCase()),
                                                'bg-light-danger': ['k','l','m','n','o','2','7'].includes((row.superUser?.nom[0]).toLowerCase()),
                                                'bg-light-warning': ['p','q','r','s','t','3','8'].includes((row.superUser?.nom[0]).toLowerCase()),
                                                'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((row.superUser?.nom[0]).toLowerCase())
                                              }"
                                            >
                                              <div class="avatar-content">{{ (row.superUser?.nom |uppercase) | initials }}</div>
                                            </div>
                                          </ng-template>
                                          <div class="cell-line-height">
                                            <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                              {{ row.superUser?.nom }}
                                            </p>
                                            <span class="text-muted font-small-2"> {{ row.superUser?.email }}</span>
                                          </div>
                                        </div>

                                        <h6 class="mt-2">{{row.superUser?.telephone}}</h6>

                                        
                                      </td>
                                      <td style="width: 60%;">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-6">
                                              <h6 class="d-flex w-100 justify-content-between">Type :<span class="ml-50 badge bg-light-success">{{ row.mensuel ? 'Mensuel' : 'Annuel' }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Factures :<span class="ml-50 badge bg-light-primary">{{ row.facture }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Devis :<span class="ml-50 badge bg-light-primary">{{ row.devis }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Bons de commande :<span class="ml-50 badge bg-light-primary">{{ row.commande_client }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Bons de livraison :<span class="ml-50 badge bg-light-primary">{{ row.bon_livraison }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Entrepôts :<span class="ml-50 badge bg-light-primary">{{ row.entrepot }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Clients :<span class="ml-50 badge bg-light-primary">{{ row.client }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Fournisseurs :<span class="ml-50 badge bg-light-primary">{{ row.fournisseur }}</span></h6>
                                            </div>
                                            <div class="col-12 col-md-12 col-lg-6 border-left">
                                              <h6 class="d-flex w-100 justify-content-between">Factures F :<span class="ml-50 badge bg-light-primary">{{ row.facture_achat }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Bons de commande F :<span class="ml-50 badge bg-light-primary">{{ row.commande_fournisseur }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Bons de réception F :<span class="ml-50 badge bg-light-primary">{{ row.bon_reception }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Entreprises :<span class="ml-50 badge bg-light-primary">{{ row.entreprise }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Utilisateurs :<span class="ml-50 badge bg-light-primary">{{ row.user }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Produits :<span class="ml-50 badge bg-light-primary">{{ row.produit }}</span></h6>
                                              <h6 class="d-flex w-100 justify-content-between">Tableaux Kanban :<span class="ml-50 badge bg-light-primary">{{ row.kanban }}</span></h6>                                              
                                            </div>
                                        </div>
                                      </td>
                                      <td>{{row.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                                      <td style="width: 200px;">
                                        <div>
                                          <button  (click)="supprimer_demandes_devis(row.id)" class="btn btn-danger btn-sm mb-50" style="width: 150px;">Supprimer</button>
                                          <button *ngIf="!row.traite" (click)="update_demandes_devis(row.id,true)"  class="btn btn-success btn-sm mb-50" style="width: 150px;">Rendre traiter</button>
                                          <button *ngIf="row.traite" (click)="update_demandes_devis(row.id,false)"  class="btn btn-warning btn-sm mb-50" style="width: 150px;">Rendre non traiter</button>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>


                          <ngb-alert *ngIf="liste_demandes_demo?.length == 0" [type]="'info'" [dismissible]="false">
                            <div class="alert-body">Aucune demande de demo </div>
                          </ngb-alert>

                      </div>
                      <!-- Permission table -->
                  </div>
                  <div class="col-12 text-center mt-2">
                      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">
                          Fermer
                      </button>
                  </div>
                </div>
              <!--/ Add role form -->
          </div>
        </ng-template>


        
          <ng-container>
            <div class="content-header row">
              <div class="content-header-left col-md-6 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12 d-flex">
                    <h2 class="content-header-title float-left mb-0">
                      Liste des entreprises
                    </h2>

                    <!-- app-breadcrumb start -->
                    <div class="breadcrumb-wrapper d-flex">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                              <a routerLink="/home">Accueil</a>
                          </li>
                          <li class="breadcrumb-item">
                              <span>Entreprises</span>
                          </li>
                      </ol>
                    </div>
                    <!-- app-breadcrumb end -->

                  </div>
                </div>
              </div>
              <ng-container>
                <div class="content-header-right text-md-right col-md-6 col-12 d-md-block2 d-none2">
                  <div class="form-group breadcrum-right">
                    <!--  -->
                    
                    <button *ngIf="liste_demandes_devis?.length>0" (click)="liste_demandes_devis_ab(modalDemandesDevis)" class="btn btn-primary mr-1 mt-50" rippleEffect>
                      <i data-feather="credit-card" class="mr-0 mr-sm-1"></i
                      ><span class="d-none2 d-sm-inline-block2">Demandes de devis ({{liste_demandes_devis?.length}})</span>
                    </button>

                    <button *ngIf="liste_demandes_demo?.length>0" (click)="liste_demandes(modalDemandes)" class="btn btn-success ml-l mt-50" rippleEffect>
                      <i data-feather="phone" class="mr-0 mr-sm-1"></i
                      ><span class="d-none2 d-sm-inline-block2">Demandes de démo ({{liste_demandes_demo?.length}})</span>
                    </button>
                      
                      
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>



          <div class="row mt-75">
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ users_connecte }}</h2>
                    <p class="card-text">Users connectés</p>
                  </div>
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="cpu" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_facebook }}</h2>
                    <p class="card-text">Visiteurs de facebook</p>
                  </div>
                  <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="server" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_google }}</h2>
                    <p class="card-text">Visiteurs de google</p>
                  </div>
                  <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="activity" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">{{ statistiques?.count_others }}</h2>
                    <p class="card-text">Autres visiteurs</p>
                  </div>
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="alert-octagon" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <section class="produits-list-wrapper">
            <div class="card">
              <!-- Invoice List Header -->
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-start align-items-center m-1">
                    <div class="d-flex align-items-center">
                      <label class="d-flex align-items-center"
                        >Afficher
                        <select class="form-control" [(ngModel)]="selectedOption">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>

                    <div  [hidden]="selected?.length == 0"  ngbDropdown class="inline ml-1">
                      <button
                        ngbDropdownToggle
                        id="dropdownSettings"
                        class="btn btn-success"
                        type="button"
                        rippleEffect
                      >
                        <i data-feather="send" class="mr-0 mr-sm-1"></i
                          ><span class="d-none2 d-sm-inline-block2">Envoyer email</span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownSettings">
                        <a ngbDropdownItem (click)="modalOpenDanger22(modalMailMulti)">
                          Email félicitation d'inscription
                        </a>
                      </div>
                    </div>


                  </div>
  
                </div>
                <div
                  class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2"
                >
                  <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
                    <div class="col-10 col-md-6 pr-0">
                      <input
                          [(ngModel)]="searchValue"
                          name="searchValue"
                          type="search"
                          class="form-control"
                          placeholder="Search..."
                          (keyup)="filterUpdate($event)"
                      />
                    </div>
                    <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0">
                      <fieldset class="form-group mb-0">
                        <ng-select
                          [items]="selectStatus"
                          [(ngModel)]="selectedStatus"
                          bindLabel="name"
                          placeholder="Select statut"
                          (change)="filterByStatus($event)"
                        ></ng-select>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <!--/ Invoice List Header -->
      
              <ngx-datatable
                [rows]="rows"
                [sorts]="[{prop: 'derniere_connexion', dir: 'desc'}]"
                [rowHeight]="58"
                class="bootstrap core-bootstrap"
                [limit]="10"
                [columnMode]="ColumnMode.force"
                [headerHeight]="40"
                [footerHeight]="50"
                [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox"
                [limit]="selectedOption"
                (activate)="onActivate($event)"
                (select)="onSelect($event)"
              >
                <ngx-datatable-column
                  [width]="50"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                        id="headerChkbxRef"
                      />
                      <label class="custom-control-label" for="headerChkbxRef"></label>
                    </div>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)"
                        id="rowChkbxRef{{ rowIndex }}"
                      />
                      <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column name="Client" prop="nom" [width]="200">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div *ngIf="row.avatar?.length > 0; else customAvatar">
                        <img
                          class="rounded-circle mr-1"
                          src="{{ _BackendUrl +'img/users/'+ row.avatar }}"
                          height="32"
                          width="32"
                          alt="datatable-avatar"
                        />
                      </div>
                      <ng-template #customAvatar>
                        <div
                          class="avatar mr-1 ml-0"
                          [ngClass]="{
                            'bg-light-primary': ['a','b','c','d','e','0','5'].includes((row.nom[0]).toLowerCase()),
                            'bg-light-success': ['f','g','h','i','j','1','6'].includes((row.nom[0]).toLowerCase()),
                            'bg-light-danger': ['k','l','m','n','o','2','7'].includes((row.nom[0]).toLowerCase()),
                            'bg-light-warning': ['p','q','r','s','t','3','8'].includes((row.nom[0]).toLowerCase()),
                            'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((row.nom[0]).toLowerCase())
                          }"
                        >
                          <div class="avatar-content">{{ (row.nom |uppercase) | initials }}</div>
                        </div>
                      </ng-template>
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25" routerLink="/details/{{row.id}}">
                          {{ row.nom }}
                        </p>
                        <span class="text-muted font-small-2"> {{ row.email }}</span>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column name="Last Cnx" prop="derniere_connexion" [width]="130">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="bbbbbb" [ngClass]="{'bg-success' : estAujourdhui(row.derniere_connexion), 'bg-secondary222' :!estAujourdhui(row.derniere_connexion) }"></div>{{ row.derniere_connexion  }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column name="Entreprise" prop="nom" [width]="150">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div *ngIf="row.entreprises[0]?.avatar?.length > 0; else customAvatar">
                        <img
                          class="rounded-circle mr-1"
                          src="{{ _BackendUrl +'img/company/'+ row.entreprises[0]?.avatar }}"
                          height="32"
                          width="32"
                          alt="datatable-avatar"
                        />
                      </div>
                      <ng-template #customAvatar>
                        <div
                          class="avatar mr-1 ml-0"
                          [ngClass]="{
                            'bg-light-primary': ['b','c','d','e','0','5'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase()),
                            'bg-light-success': ['f','g','h','i','j','1','6'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase()),
                            'bg-light-danger': ['k','l','m','n','o','2','7'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase()),
                            'bg-light-warning': ['p','q','r','s','t','3','8'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase()),
                            'bg-light-info': ['u','v','w','x','z','y','4','9'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase()),
                            'bg-light-secondary': ['a'].includes((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom[0] : 'a').toLowerCase())
                          }"
                        >
                          <div class="avatar-content">{{ ((row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom : 'anonyme') |uppercase) | initials }}</div>
                        </div>
                      </ng-template>
                      <div class="cell-line-height">
                        <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                          {{ row.entreprises[0]?.nom?.length > 0 ? row.entreprises[0]?.nom : 'Anonyme' }}
                        </p>
                        <span class="text-muted font-small-2"> {{ row.entreprises[0]?.devise}}</span>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
  
                
                <ngx-datatable-column name="Abonnement" prop="abonnement" [width]="130">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <ng-template #tipContentVenteee5>
                      <div *ngFor="let ab of row.abonnements" class="mb-50">{{ab.nom }} : {{ab.statut}} 
                        <strong *ngIf="ab.mensuel">{{ ab.plan_mensuel_prix_mois  | currency:currency:'symbol' }}</strong>
                        <strong *ngIf="!ab.mensuel">{{ ab.plan_annuel_prix_an  | currency:currency:'symbol' }}</strong>
                      </div>
                    </ng-template>

                    <div class="badge rounded-pill" placement="top" [ngbTooltip]="tipContentVenteee5" container="body"
                    [ngClass]="{
                      'badge-light-primary':  getAbonnementEnCour(row.abonnements) == 'En cour' && getAbonnementNom(row.abonnements) == 'Essai Gratuit' ,
                      'badge-light-secondary': getAbonnementEnCour(row.abonnements) !== 'En cour',
                      'badge-light-success': getAbonnementEnCour(row.abonnements) == 'En cour' && getAbonnementNom(row.abonnements) !== 'Essai Gratuit', 
                      'badge-light-danger': getAbonnementEnCour(row.abonnements) == 'En cour' && getAbonnementNom(row.abonnements) === 'Premium' 
                    }">
                      {{ getAbonnementNom(row.abonnements)  }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Pays" prop="nom" [width]="90">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    
                    <ng-template #tipContentVenteee>
                      <div class="mb-50">{{ row.entreprises[0]?.adresse  }} <br> {{ row.entreprises[0]?.ville  }} <br> {{ getPays(row.entreprises[0]?.pays)  }} </div>
                    </ng-template>

                    <div placement="top" [ngbTooltip]="tipContentVenteee" container="body">
                      <i class=" mr-50 flag-icon flag-icon-{{getPaysCode(row.entreprises[0]?.pays) | lowercase  }}"></i> {{ getPays(row.entreprises[0]?.pays)  }}
                    </div>
                    
                  </ng-template>
                </ngx-datatable-column>

              
                <ngx-datatable-column name="Détails Ste" prop="nom" [width]="150">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div>{{ row.entreprises[0]?.email  }}</div>
                    <!-- <div *ngIf="row.entreprises[0]?.telephone">{{ row.entreprises[0]?.telephone  }}</div> -->
                    <div>{{ row.telephone  }}</div>
                  </ng-template>
                </ngx-datatable-column>


         
                <ngx-datatable-column name="Ajouté le" prop="createdAt" [width]="70">
                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{ row.createdAt | date  }}
                  </ng-template>
                </ngx-datatable-column>
  
  
                <ngx-datatable-column name="Actions" [width]="50" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <div class="d-flex align-items-center col-actions">
                        <a class="mr-1" href="javascript:void(0);" (click)="details_user(modalDetails)" container="body" placement="top" ngbTooltip="Send Mail"
                          ><i size="18" data-feather="list"></i
                        ></a>
                        <a
                          class="mr-1"
                          routerLink="/details/{{row.id}}"
                          container="body"
                          placement="top"
                          ngbTooltip="Détails user"
                          ><i size="18" data-feather="eye"></i
                        ></a>
                        <div ngbDropdown container="body">
                          <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow px-0" rippleEffect>
                            <i data-feather="more-vertical" size="18" class="cursor-pointer"></i>
                          </button>
                          <div ngbDropdownMenu>
                            <a  href="javascript:void(0)" (click)="modalOpenDanger(modalDelete,row.id)"ngbDropdownItem><i data-feather="trash" class="mr-1"></i>Supprimer</a
                            >
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </section>



    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AuthGuard } from 'app/auth/helpers';
import { Role } from 'app/auth/models';

import { CoreCommonModule } from '@core/common.module';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

import { DashboardService } from 'app/main/dashboard/dashboard.service';

import { AnalyticsComponent } from 'app/main/dashboard/analytics/analytics.component';
import { EcommerceComponent } from 'app/main/dashboard/ecommerce/ecommerce.component';
import { HomeComponent } from './home/home.component';
import { MyPermissionsService } from 'app/auth/service/my-permissions.service';


// swiper configuration
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


const routes = [
  // {
  //   path: 'analytics',
  //   component: AnalyticsComponent,
  //   canActivate: [AuthGuard],
  //   data: { roles: [Role.Admin], animation: 'danalytics' },
  //   resolve: {
  //     css: DashboardService,
  //   }
  // },
  {
    path: 'dashboard',
    component: EcommerceComponent,
    canActivate: [AuthGuard],
    resolve: {
      css: DashboardService
    },
    data: { animation: 'dachecommerce' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: {
      css: MyPermissionsService
    },
    data: { animation: 'dachecommerce' }
  }
];

@NgModule({
  declarations: [AnalyticsComponent, EcommerceComponent, HomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgbModule,
    PerfectScrollbarModule,
    CoreCommonModule,
    NgApexchartsModule,
    SwiperModule
  ],
  providers: [DashboardService,MyPermissionsService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  exports: [EcommerceComponent]
})
export class DashboardModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  // Public
  public apiData:any = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
    this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
        let data = {
        messages: [
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-12.jpg',
            heading: '<span class="font-weight-bolder">Revised Order 👋</span>  checkout',
            text: 'MD Inc. order updated',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-15.jpg',
            heading: '<span class="font-weight-bolder">Congratulation Sam 🎉</span> winner!',
            text: 'Won the monthly best seller badge.',
            date : '20 OCT 2022, 17:00'
          },
          {
            image: 'assets/images/portrait/small/avatar-s-3.jpg',
            heading: '<span class="font-weight-bolder">New message</span>  received',
            text: 'You have 10 unread messages',
            date : '20 OCT 2022, 17:00'
          },
        ]
      };

      this.apiData = data;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);

    });
  }
}
